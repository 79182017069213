import React from 'react'
import PropTypes from 'prop-types'
import { Footer } from '@components/Footer'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from '@hooks'

const handleNavigationItemTagLink = navigationLink => ({
  ...navigationLink,
  slug:
    navigationLink?.contentType === 'ContentfulTag' ||
    navigationLink?.contentType === 'tag'
      ? `tag/${navigationLink.slug}`
      : navigationLink?.slug
      ? navigationLink.slug
      : null,
})

const handleFormatNavigationItem = navigationItem => {
  let { navigationLink, subnavigationItems, ...rest } = navigationItem

  return {
    navigationLink: handleNavigationItemTagLink(navigationLink),
    subnavigationItems: subnavigationItems
      ? subnavigationItems.map(subNavItem =>
          handleFormatNavigationItem(subNavItem)
        )
      : null,
    ...rest,
  }
}

const FooterSpacecampGatsbyCDAContainer = props => {
  const { footer, copyrightText } = useStaticQuery(graphql`
    query FooterSpacecampQuery {
      footer: allContentfulModuleNavigation(
        filter: { placement: { eq: "Footer" } }
      ) {
        nodes {
          ...navigationFields
        }
      }
      copyrightText: allContentfulSiteInformationAndBranding {
        nodes {
          ...copyRight
        }
      }
    }
  `)

  const { getLocalizedFields } = useLocale()
  const localizedFooter = getLocalizedFields(footer?.nodes)
  const navigationItems = localizedFooter?.navigationItems.map(navItem =>
    handleFormatNavigationItem(navItem)
  )

  const copyright = copyrightText?.nodes?.[0].copyright

  return (
    <Footer
      layout="spacecamp"
      navItems={navigationItems}
      copyright={copyright}
      {...props}
    />
  )
}

FooterSpacecampGatsbyCDAContainer.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      navigationLink: PropTypes.shape({
        displayTextAlt: PropTypes.string,
        slug: PropTypes.string,
      }),
      subnavigationItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          navigationLink: PropTypes.shape({
            displayTextAlt: PropTypes.string,
            url: PropTypes.string,
            slug: PropTypes.string,
          }),
        })
      ),
      title: PropTypes.string,
    })
  ),
  copyright: PropTypes.string,
}

export default FooterSpacecampGatsbyCDAContainer
