import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledImg } from '../styles'
import { useLightMode } from '@hooks/'
import { Logo } from '@components/Button'
import { focusLastNavItem } from '../utils'

const NavLogo = (
  {
    logo: primaryLogo,
    secondaryLogo,
    homeSlug = '/',
    toggled = false,
    setToggled = () => {},
    forceShowNavbar = () => {},
    styles = {},
    onClick,
    onKeyDown,
    onFocus,
  },
  ref
) => {
  const [isLightMode] = useLightMode()
  const logo = isLightMode ? primaryLogo : secondaryLogo || primaryLogo

  const defaultHandleKeyDown = e => {
    const skipLink = document
      ?.querySelector('#skipLink')
      ?.querySelector('button')

    if (!skipLink && e.shiftKey && e.key === 'Tab') {
      focusLastNavItem({ e, toggled })
    }
    if (e.key === 'Enter' || e.key === 'Escape') {
      setToggled(false)
    }
  }

  if (!logo) return null

  return (
    <StyledContainer
      id="navLogo"
      onFocus={onFocus || forceShowNavbar}
      ref={ref}
    >
      <Logo
        to={homeSlug}
        height="fit-content"
        onKeyDown={onKeyDown || defaultHandleKeyDown}
        onClick={onClick}
        display="block"
      >
        <StyledImg height="25px" src={logo?.src} alt={logo?.alt} {...styles} />
      </Logo>
    </StyledContainer>
  )
}

export default React.forwardRef(NavLogo)
