import React from 'react'
import PropTypes from 'prop-types'
import { FooterTitle, StyledNavLink } from '../styles'

const FooterItem = ({ title = '', navigationLink = {}, fontSize = '14px' }) => {
  if (!title) return null

  return (
    <StyledNavLink to={navigationLink?.slug} href={navigationLink?.url}>
      <FooterTitle
        fontSize={fontSize}
        fontWeight="bold"
        alignItems="center"
        my={2}
      >
        {title}
      </FooterTitle>
    </StyledNavLink>
  )
}

FooterItem.propTypes = {
  title: PropTypes.string,
  navigationLink: PropTypes.shape({
    displayTextAlt: PropTypes.string,
    url: PropTypes.string,
    slug: PropTypes.string,
  }),
  subnavigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      navigationLink: PropTypes.shape({
        displayTextAlt: PropTypes.string,
        url: PropTypes.string,
        slug: PropTypes.string,
      }),
    })
  ),
}

export default FooterItem
