import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FooterSubLinkStyle, StyledNavLink } from '../styles'
import { useLocale } from '@hooks'

const FooterSubLink = ({
  title = '',
  navigationLink = {},
  setActiveSubnav = () => {},
  ...rest
}) => {
  const slug = navigationLink?.slug
  const { isActiveSlug } = useLocale()

  useEffect(() => {
    if (isActiveSlug(slug)) {
      setActiveSubnav({
        parent: { slug, title },
      })
    }
  }, [title, isActiveSlug, setActiveSubnav, slug])

  return (
    <FooterSubLinkStyle
      display="flex"
      justifyContent="start"
      alignItems="center"
      fontSize={16}
      mb={2}
      fontWeight={100}
    >
      <StyledNavLink
        href={navigationLink?.url}
        to={slug}
        aria-current={isActiveSlug(slug) ? 'page' : null}
      >
        {title}
      </StyledNavLink>
    </FooterSubLinkStyle>
  )
}

FooterSubLink.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
}

export default FooterSubLink
