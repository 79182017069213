import styled from 'styled-components'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'
import theme from '@theme'
import { hexToRgba } from '../../utils'

export const BaseButton = styled.button`
  text-transform: uppercase;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  box-shadow: none;
  background-image: none;
  line-height: 1;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s ease-in-out;
  
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const BaseA = styled.a`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`
export const InternalASpan = styled.span`
  transition: all 0.15s ease-in-out;
  border-bottom: 2px solid ${({ color }) =>
    !color
      ? hexToRgba(theme.colors.magenta, 0)
      : hexToRgba(theme.colors[color], 0)};
  &:hover {
    color: ${({ hover }) =>
      !hover ? theme.colors.magenta : theme.colors[hover]};
    border-color: ${({ hover }) =>
      !hover
        ? hexToRgba(theme.colors.magenta, 1)
        : hexToRgba(theme.colors[hover], 1)};
  }
  cursor: pointer;
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const HyperlinkSpan = styled.span`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
  transition: color .15s ease-in-out;
  &:hover {
    color: ${process.env.GATSBY_SPACECAMP ? 'white' : '#F215A1'};
    border-bottom: 2px solid;
  }
`

export const InternalAPointerSpan = styled.span`
  ${typography}
  ${space}
`

export const StyledDiv = styled.div`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const InternalAUnstyled = styled.span`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const FakeButtonA = styled(BaseA)`
  border: 2px solid white;
`
