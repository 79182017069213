import { useEffect, useState } from 'react'

const throttle = (func, threshhold = 250, scope) => {
  let last
  let deferTimer
  return function() {
    const context = scope || this

    const now = +new Date()
    const args = arguments
    if (last && now < last + threshhold) {
      // HOLLLLLLLLD IT
      clearTimeout(deferTimer)
      deferTimer = setTimeout(() => {
        last = now
        func.apply(context, args)
      }, threshhold)
    } else {
      last = now
      func.apply(context, args)
    }
    // return arr;
  }
}

const useWindowScrollPosition = ({ startAtZero = false }) => {
  let throttleMs = 100
  const [scroll, setScroll] = useState({ x: 0, y: 0 })

  useEffect(() => {
    setScroll({
      x: startAtZero ? 0 : window.pageXOffset,
      y: startAtZero ? 0 : window.pageYOffset,
    })
  }, [startAtZero])

  const handle = throttle(() => {
    if (typeof window !== 'undefined') {
      setScroll({
        x: window.pageXOffset,
        y: window.pageYOffset,
      })
    }
  }, throttleMs)

  useEffect(() => {
    window.addEventListener('scroll', handle)

    return () => {
      window.removeEventListener('scroll', handle)
    }
  }, [handle])

  return scroll
}

export default useWindowScrollPosition
