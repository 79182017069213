import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '../styles'
import NavigationList from './NavigationList'
import Copyright from './Copyright'
import FooterLogo from './FooterLogo'
import SocialMedia from './SocialMedia'

const FooterDesktop = ({
  logo,
  row1NavigationItems,
  row2NavigationItems,
  row3NavigationItems,
  copyright = '',
  socialMedia,
  homeSlug,
}) => {
  return (
    <StyledContainer
      display="flex"
      flexDirection="column"
      alignItems="center"
      mx="auto"
      maxWidth={[
        'maxWidth.xsmall',
        'maxWidth.small',
        'maxWidth.medium',
        'maxWidth.large',
        'maxWidth.xlarge',
        'maxWidth.xxlarge',
      ]}
      pt={3}
    >
      <StyledContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderTop="1px solid black"
        borderBottom="1px solid black"
        width="100%"
      >
        <FooterLogo logo={logo} homeSlug={homeSlug} />
        <NavigationList navItems={row1NavigationItems} my={2} />
      </StyledContainer>
      <StyledContainer
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <StyledContainer dispaly="flex" flexDirection="column">
          <NavigationList navItems={row2NavigationItems} fontSize="16px" />
          <NavigationList
            navItems={row3NavigationItems}
            fontSize="14px"
            mb={2}
          />
          <SocialMedia socialMedia={socialMedia} />
        </StyledContainer>
        <Copyright copyright={copyright} />
      </StyledContainer>
    </StyledContainer>
  )
}

FooterDesktop.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
}

export default FooterDesktop
