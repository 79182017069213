import { useLayoutEffect } from 'react'

const useLockBodyScroll = (toggled = true) => {
  useLayoutEffect(() => {
    if (toggled) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [toggled])
}

export default useLockBodyScroll
