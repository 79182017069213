import React from 'react'
import PropTypes from 'prop-types'
import { Logo } from '@components/Button'
import { StyledImg } from '../styles'
import { StyledContainer } from '@components/Icon/styles'

const FooterLogo = ({ logo, homeSlug = '/', ...rest }) => {
  return (
    <StyledContainer {...rest}>
      <Logo to={homeSlug} height="fit-content" display="block">
        <StyledImg height="25px" src={logo?.src} alt={logo?.alt} />
      </Logo>
    </StyledContainer>
  )
}

FooterLogo.propTypes = {
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  homeSlug: PropTypes.string,
}

export default FooterLogo
