/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'
import Box from '@components/Box'

const StyledMain = styled.main`
  max-width: 100vw;
  min-height: calc(100vh - 256px);
  outline: none;
  &:focus {
    outline: none;
    border: none;
  }
`

export default ({ children }) => (
  <StyledMain id="main" tabIndex="-1">
    <Box width="100%" display="flex" justifyContent="center">
      <Box
        maxWidth={[
          'maxWidth.xsmall',
          'maxWidth.small',
          'maxWidth.medium',
          'maxWidth.large',
          'maxWidth.xlarge',
          'maxWidth.xxlarge',
        ]}
        px={[3, 3, 0]}
        width="100%"
      >
        {children}
      </Box>
    </Box>
  </StyledMain>
)
