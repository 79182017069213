import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from '@hooks'
import { NavigationLayout } from '@components/Navigation'



const handleNavigationItemTagLink = (navigationLink) => ({
  ...navigationLink,
  slug: navigationLink?.contentType === 'ContentfulTag' || navigationLink?.contentType === 'tag'
  ? `tag/${navigationLink.slug}`
  : navigationLink?.slug
    ? navigationLink.slug
    : null
})

const handleFormatNavigationItem = (navigationItem) => {
  let { navigationLink, subnavigationItems, ...rest } = navigationItem;

  return ({
    navigationLink: handleNavigationItemTagLink(navigationLink),
    subnavigationItems: subnavigationItems
      ? subnavigationItems.map(subNavItem => handleFormatNavigationItem(subNavItem))
      : null,
    ...rest
  })
}

const NavigationGatsbyCDAContainer = props => {
  const { header, siteInfo } = useStaticQuery(graphql`
    query HeaderQuery {
      header: allContentfulModuleNavigation(filter: { placement: { eq: "Header" } }) {
        nodes {
          ...navigationFields
        }
      }
      siteInfo: allContentfulSiteInformationAndBranding {
        nodes {
          ...siteInfoFields
        }
      }
    }
  `)

  const { getLocalizedFields } = useLocale()
  const localizedHeader = getLocalizedFields(header?.nodes)
  const navigationItems = localizedHeader?.navigationItems.map(navItem => handleFormatNavigationItem(navItem))
  let logo = siteInfo?.nodes?.[0]?.logo
  logo = logo && {
    src: logo?.file?.url,
    alt: logo?.description || logo?.title,
  }
  let secondaryLogo = siteInfo?.nodes?.[0]?.secondaryLogo
  secondaryLogo = secondaryLogo && {
    src: secondaryLogo?.file?.url,
    alt: secondaryLogo?.description || secondaryLogo?.title,
  }
  return (
    <NavigationLayout
      navItems={navigationItems}
      logo={logo}
      secondaryLogo={secondaryLogo}
      {...props}
    />
  )
}

NavigationGatsbyCDAContainer.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.string,
}

export default NavigationGatsbyCDAContainer
