import { useEffect } from 'react'
import createPersistedGlobalState from './useGlobalState'
import { useVisitedState } from './index'
const useAcceptedTermsState = createPersistedGlobalState('acceptedTerms')

const useAcceptedState = props => {
  const [acceptedTerms, setAcceptedTerms] = useAcceptedTermsState(false)
  const [visited, setVisited] = useVisitedState(false)

  useEffect(() => {
    if (!acceptedTerms) {
      window['ga-disable-UA-7438605-14'] = true
    } else {
      window['ga-disable-UA-7438605-14'] = false
    }
  }, [acceptedTerms])

  const handleAccept = () => {
    setAcceptedTerms(true)
    setVisited(true)
  }

  const handleReject = () => {
    setAcceptedTerms(false)
    setVisited(true)
  }

  return {
    acceptedTerms,
    handleAccept,
    handleReject,
    visited,
  }
}

export default useAcceptedState
