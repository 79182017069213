import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '../styles'
import { Logo } from '@components/Button'
import Icon from '@components/Icon'

const SocialMedia = ({ socialMedia, size = 14 }) => {
  if (!socialMedia) return null

  const getGlyph = linkType => {
    switch (linkType) {
      case 'LinkedIn':
        return 'linkedIn'
      case 'Twitter':
        return 'twitter'
      case 'Youtube':
        return 'youtube'
      case 'Instagram':
        return 'instagram'
      case 'Facebook':
        return 'facebook'
      default:
        return null
    }
  }

  return (
    <StyledContainer mt={2} mb={3}>
      {socialMedia?.map(({ id, linkType, url }, i) => (
        <Logo
          key={id}
          href={url}
          display="inline-block"
          bg="black"
          borderRadius="50%"
          m={1}
          ml={i === 0 ? 0 : 1}
        >
          <Icon
            glyph={getGlyph(linkType)}
            height={size}
            width={size}
            fill="white"
            viewBox={linkType === 'Facebook' ? '-1 -1 20 20' : '0 0 24 24'}
          />
        </Logo>
      ))}
    </StyledContainer>
  )
}

SocialMedia.propTypes = {
  socialMedia: PropTypes.shape({
    id: PropTypes.string,
    linkType: PropTypes.string,
    url: PropTypes.string,
  }),
  size: PropTypes.number,
}

export default SocialMedia
