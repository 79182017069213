import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledBtnBox,
  StyledMenuBtnContainer,
  StyledMenuBtnLine,
} from '../styles'

const NavMenuBtn = (
  {
    toggled = false,
    setToggled = () => {},
    forceShowNavbar = () => {},
    onClick,
    onKeyDown,
    onFocus,
    containerStyles = {},
    boxStyles = {},
    lineStyles = {},
  },
  ref
) => {
  const handleMenuBtnClick = () => setToggled(t => !t)
  const handleMenuBtnKeyDown = e => {
    if (e.key === 'Enter') {
      setToggled(t => !t)
    }
    if (e.key === 'Escape') {
      setToggled(false)
    }
  }

  return (
    <StyledMenuBtnContainer
      ref={ref}
      data-testid="menu-btn"
      id="menuBtn"
      aria-label="Mobile Navigation Trigger"
      role="button"
      aria-expanded={toggled ? 'true' : 'false'}
      tabIndex="0"
      onClick={onClick || handleMenuBtnClick}
      onKeyDown={onKeyDown || handleMenuBtnKeyDown}
      onFocus={onFocus || forceShowNavbar}
      p={1}
      {...containerStyles}
    >
      <StyledBtnBox width="26px" height="24px" tabIndex="-1" {...boxStyles}>
        <StyledMenuBtnLine
          width="26px"
          toggled={toggled}
          bg={process.env.GATSBY_SPACECAMP ? "white" : "black"}
          {...lineStyles}
        />
      </StyledBtnBox>
    </StyledMenuBtnContainer>
  )
}

export default React.forwardRef(NavMenuBtn)
