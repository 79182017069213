import AyzenbergTheme from './AyzenbergTheme'
import SpaceCampTheme from './SpaceCampTheme'

let theme =
  !process.env.GATSBY_SPACECAMP ||
  process.env.GATSBY_SPACECAMP === 'false' ||
  process.env.GATSBY_SPACECAMP === 'FALSE'
    ? AyzenbergTheme
    : SpaceCampTheme

export default theme
