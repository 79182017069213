/* eslint-disable camelcase */
import { useStaticQuery, graphql } from 'gatsby'
import React, { useContext } from 'react'
import { LocaleContext } from '../context'

export const useLocale = () => {
  return useContext(LocaleContext)
}

export const LocaleProvider = ({ children, locale }) => {
  const localeSlug = locale === 'en-US' ? '' : `${locale}`
  const getLocalizedSlug = slug => {
    if (!slug) return slug
    if (slug === '/' && localeSlug) return localeSlug
    if (slug.includes(localeSlug)) return slug
    return localeSlug ? `${localeSlug}/${slug}` : slug
  }

  let currentSlug, currentQuery, currentPath

  if (typeof window !== 'undefined') {
    currentSlug = window?.location?.pathname.slice(1)
    currentQuery = window?.location?.search
    currentPath = `${currentSlug}${currentQuery}`
  }

  const isActiveSlug = slug =>
    currentPath === getLocalizedSlug(slug) ||
    (currentPath === localeSlug && slug === 'home')

  const getLocalizedFields = arr =>
    arr.filter(({ node_locale }) => node_locale === locale)?.[0]

  const { locales } = useStaticQuery(graphql`
    query LocalesQuery {
      locales: allContentfulLocale {
        nodes {
          ...localeFields
        }
      }
    }
  `)

  const defaultLocale = locales?.nodes?.find(locale => locale.default)

  return (
    <LocaleContext.Provider
      value={{
        locale,
        defaultLocale: defaultLocale?.code,
        locales: locales?.nodes,
        localeSlug,
        getLocalizedSlug,
        getLocalizedFields,
        currentSlug,
        currentQuery,
        currentPath,
        isActiveSlug,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}
