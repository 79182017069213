import React from 'react'
import PropTypes from 'prop-types'
import PageAnimationLink from './PageAnimationLink'
import { useLocale, useTranslatedTerms } from '@hooks/index.js'
import { addLeadingSlash, removeTrailingSlash } from '@utils/'

const InternalLink = ({
  id,
  to,
  anchorTagId,
  children,
  onKeyDown,
  onFocus,
  tabIndex,
  onDragStart,
  onMouseDown,
  onMouseUp,
  onDragEnd,
  width,
  linkStyling = {},
  ...rest
}) => {
  const { getLocalizedSlug, currentPath, lowercaseLocaleSlug } = useLocale()
  const { getTranslatedTerm } = useTranslatedTerms()

  const localizedSlug = getLocalizedSlug(to)
  let sanitizedSlug = addLeadingSlash(localizedSlug)
  sanitizedSlug = sanitizedSlug?.replace(`/${getTranslatedTerm('home')}`, '/')
  sanitizedSlug = removeTrailingSlash(sanitizedSlug)
  sanitizedSlug =
    sanitizedSlug === `/${lowercaseLocaleSlug}`
      ? `/${lowercaseLocaleSlug}/`
      : sanitizedSlug

  if (sanitizedSlug && anchorTagId) {
    sanitizedSlug = `${sanitizedSlug}#${anchorTagId}`
  } else if (anchorTagId) {
    sanitizedSlug = `/${currentPath}#${anchorTagId}`
  }

  const focusAnchor = () => {
    if (anchorTagId) {
      const node = document.querySelector(`#${anchorTagId}`)
      if (node) {
        node.scrollIntoView({ behavior: 'smooth' })
        node.focus({ preventScroll: true })
      }
    }
  }

  return (
    <PageAnimationLink
      duration={1}
      id={id}
      data-testid="internal-btn"
      role="link"
      to={sanitizedSlug}
      onKeyDown={onKeyDown}
      onClick={focusAnchor}
      onFocus={onFocus}
      style={{
        width: width || 'fit-content',
        ...linkStyling,
      }}
      tabIndex={tabIndex}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      {...rest}
    >
      {children}
    </PageAnimationLink>
  )
}

InternalLink.propTypes = {}

export default InternalLink
