import React from 'react'
import PropTypes from 'prop-types'
import FooterAyz from './components'
import FooterSpacecamp from './componentsSpacecamp'

const Footer = ({ layout = 'ayz', navItems, ...rest }) => {
  switch (layout) {
    case 'spacecamp':
      return <FooterSpacecamp navItems={navItems} {...rest} />
    case 'ayz':
    default:
      return <FooterAyz {...navItems} {...rest} />
  }
}

Footer.propTypes = {
  layout: PropTypes.string,
}

export default Footer
