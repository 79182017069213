import React from 'react'
import PropTypes from 'prop-types'
import { StyledSvgContainer, InlineSvg } from './styles'

export const Glyph = ({ glyph, fill }) => {
  switch (glyph) {
    case 'accordion-arrow':
      return <path fill={fill} d="M5 8l4 4 4-4z" />
    case 'play':
      return (
        <>
          <path
            fill={fill}
            d="M32 64C14.4 64 0 49.6 0 32S14.4 0 32 0s32 14.4 32 32-14.4 32-32 32zm0-60C16.6 4 4 16.6 4 32s12.6 28 28 28 28-12.6 28-28S47.4 4 32 4z"
          />
          <path fill={fill} d="M25 19l18 13-18 13V19z" />
        </>
      )
    case 'add':
      return <path fill={fill} d="M7 16V9H0V7h7V0h2v7h7v2H9v7z" />
    case 'add-fill':
      return (
        <path
          fill={fill}
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z"
        />
      )
    case 'attention':
      return (
        <path
          fill={fill}
          data-name="Path 35"
          d="M8 0a8 8 0 108 8 8.024 8.024 0 00-8-8zm1.1 12.2H6.9v-1.9h2.3v1.9zm.1-7.4l-.6 4.4H7.4l-.6-4.4v-1h2.5v1z"
        />
      )
    case 'bell':
      return (
        <path
          fill={fill}
          d="M14.937 14h-5a2 2 0 01-4 0h-5a.961.961 0 01-.9-.7 1.068 1.068 0 01.3-1.1 4.026 4.026 0 001.6-3.2V6a6 6 0 0112 0v3a4.026 4.026 0 001.6 3.2.947.947 0 01.3 1.1.961.961 0 01-.9.7z"
        />
      )
    case 'checkmark':
      return (
        <path
          fill={fill}
          d="M6.345 13.034L0 6.69 1.287 5.4l5.058 4.968L14.713 2 16 3.287z"
        />
      )
    case 'chat':
      return (
        <path
          fill={fill}
          d="M5 8a.945.945 0 001 1h4a1 1 0 001-1 .945.945 0 00-1-1H6a1 1 0 00-1 1zM0 5a4.951 4.951 0 015-5h6a4.951 4.951 0 015 5v10a.945.945 0 01-1 1H5a4.951 4.951 0 01-5-5z"
        />
      )
    case 'clock':
      return (
        <path
          fill={fill}
          d="M2.4 2.4A7.263 7.263 0 018 0a7.263 7.263 0 015.6 2.4A7.263 7.263 0 0116 8a7.263 7.263 0 01-2.4 5.6A7.263 7.263 0 018 16a7.263 7.263 0 01-5.6-2.4A7.984 7.984 0 010 8a7.263 7.263 0 012.4-5.6zm9.2 9.2l.933-.933L9.2 7.333 8 2H6.667v6a1.21 1.21 0 00.4.933.466.466 0 00.267.133z"
        />
      )
    case 'close':
      return (
        <path
          fill={fill}
          d="M11.121,9.707c-0.39,-0.391 -1.024,-0.391 -1.414,0c-0.391,0.39 -0.391,1.024 0,1.414l4.95,4.95l-4.95,4.95c-0.391,0.39 -0.391,1.023 0,1.414c0.39,0.39 1.024,0.39 1.414,0l4.95,-4.95l4.95,4.95c0.39,0.39 1.023,0.39 1.414,0c0.39,-0.391 0.39,-1.024 0,-1.414l-4.95,-4.95l4.95,-4.95c0.39,-0.39 0.39,-1.024 0,-1.414c-0.391,-0.391 -1.024,-0.391 -1.414,0l-4.95,4.95l-4.95,-4.95Z"
        />
      )
    case 'down':
      return (
        <path
          fill={fill}
          d="M16 8l-1.455-1.455-5.506 5.506V0H6.961v12.052L1.455 6.545 0 8l8 8z"
        />
      )
    case 'down-caret':
      return <path fill={fill} d="M4.7 6.1L0 1.4 1.4 0l3.3 3.3L8 0l1.4 1.4z" />
    case 'facebook':
      return (
        <path
          style={{ filter: 'url(#shadow)' }}
          fill={fill}
          d="M9.422 16.001V8.712h2.489l.356-2.844H9.422V4.09c0-.8.267-1.422 1.422-1.422h1.511V.09C12 .09 11.111.001 10.133.001a3.431 3.431 0 00-3.644 3.733v2.134H4v2.844h2.489v7.289z"
        />
      )
    case 'instagram':
      return (
        <path
          fill={fill}
          d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
        />
      )
    case 'heart':
      return (
        <path
          fill={fill}
          d="M14.721 1.275a4.3 4.3 0 00-6.119 0l-.6.6-.6-.6a4.331 4.331 0 00-6.127 6.123l6.721 6.721 6.721-6.721a4.3 4.3 0 000-6.119"
        />
      )
    case 'left':
      return (
        <path
          fill={fill}
          d="M8 16l1.455-1.455-5.506-5.506H16V6.961H3.948l5.507-5.506L8 0 0 8z"
        />
      )
    case 'left-caret':
      return (
        <path fill={fill} d="M0 4.7L4.7 0l1.4 1.4-3.3 3.3L6.1 8 4.7 9.4z" />
      )
    case 'linkedIn':
      return (
        <path
          fill={fill}
          d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0ZM8,19H5V8H8ZM6.5,6.73A1.77,1.77,0,1,1,8.25,5,1.75,1.75,0,0,1,6.5,6.73ZM20,19H17V13.4c0-3.37-4-3.12-4,0V19H10V8h3V9.77c1.4-2.59,7-2.78,7,2.47Z"
        />
      )
    case 'menu':
      return <path fill={fill} d="M0 14v-2h10v2zm0-6V6h16v2zm0-6V0h16v2z" />
    case 'menu-dots':
      return (
        <path
          fill={fill}
          d="M7 14.5A1.5 1.5 0 118.5 16 1.5 1.5 0 017 14.5zm0-6A1.5 1.5 0 118.5 10 1.5 1.5 0 017 8.5zm0-6A1.5 1.5 0 118.5 4 1.5 1.5 0 017 2.5z"
        />
      )
    case 'person':
      return (
        <path
          fill={fill}
          d="M0 16v-2c0-2.2 3.6-4 8-4s8 1.8 8 4v2zM4 4a4 4 0 114 4 4 4 0 01-4-4z"
        />
      )
    case 'quote':
      return (
        <path
          fill={fill}
          d="M5.9 8.4v6.4H0V9.7a13.251 13.251 0 011-6A8.275 8.275 0 015.1 0l1.4 2.2A5.253 5.253 0 004 4.3a7.555 7.555 0 00-.9 4.1zm9.6 0v6.4H9.6V9.7c0-2.8.3-4.7 1-6A8.275 8.275 0 0114.7 0L16 2.2a5.253 5.253 0 00-2.5 2.1 9.975 9.975 0 00-.9 4.1z"
        />
      )
    case 'right':
      return (
        <path
          fill={fill}
          d="M8 0L6.545 1.455l5.506 5.506H0v2.078h12.052l-5.507 5.506L8 16l8-8z"
        />
      )
    case 'right-caret':
      return <path fill={fill} d="M6.1 4.7L1.4 9.4 0 8l3.3-3.3L0 1.4 1.4 0z" />
    case 'search':
      return (
        <path
          fill={fill}
          d="M15.9 14.5l-3.3-3.3A6.847 6.847 0 0014 7a6.957 6.957 0 00-7-7 6.957 6.957 0 00-7 7 6.957 6.957 0 007 7 6.847 6.847 0 004.2-1.4l3.3 3.3zM2 7a4.951 4.951 0 015-5 4.951 4.951 0 015 5 4.951 4.951 0 01-5 5 4.951 4.951 0 01-5-5z"
        />
      )
    case 'share':
      return (
        <path
          style={{ filter: 'url(#shadow)' }}
          fill={fill}
          d="M11 10a2.987 2.987 0 00-1.868.653l-3.2-2a3.017 3.017 0 000-1.3l3.2-2a2.995 2.995 0 10-1.06-1.7l-3.2 2a3 3 0 100 4.694l3.2 2A3 3 0 1011 10z"
        />
      )
    case 'tiktok':
      return (
        <path
          fill={fill}
          d="M24 4H6a2 2 0 00-2 2v18a2 2 0 002 2h18a2 2 0 002-2V6a2 2 0 00-2-2zm-1.311 9.474a4.27 4.27 0 01-3.967-1.911v6.577a4.861 4.861 0 11-4.861-4.862c.102 0 .201.009.3.015v2.396c-.1-.012-.197-.03-.3-.03a2.481 2.481 0 000 4.962c1.371 0 2.581-1.08 2.581-2.45l.024-11.17h2.289a4.268 4.268 0 003.934 3.811v2.662z"
        />
      )
    case 'twitter':
      return (
        <path
          style={{ filter: 'url(#shadow)' }}
          fill={fill}
          d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
        />
      )
    case 'up':
      return (
        <path
          fill={fill}
          d="M0 8l1.455 1.455 5.506-5.506V16h2.078V3.948l5.506 5.507L16 8 8 0z"
        />
      )
    case 'up-caret':
      return (
        <path fill={fill} d="M4.7 0l4.7 4.7L8 6.1 4.7 2.8 1.4 6.1 0 4.7z" />
      )
    case 'world':
      return (
        <path
          fill={fill}
          d="M2.4 2.4A7.263 7.263 0 018 0a7.263 7.263 0 015.6 2.4A7.263 7.263 0 0116 8a7.263 7.263 0 01-2.4 5.6A7.263 7.263 0 018 16a7.263 7.263 0 01-5.6-2.4A7.984 7.984 0 010 8a7.263 7.263 0 012.4-5.6zm6.667 12a3.276 3.276 0 002-1.2A5.824 5.824 0 0012 10.4a2.208 2.208 0 00-.667-1.6A2.347 2.347 0 009.6 8H8.267a3.893 3.893 0 01-1.2-.267 1.21 1.21 0 01-.4-.933.693.693 0 01.267-.533A1.011 1.011 0 017.467 6a.91.91 0 01.667.4c.267.133.4.267.533.267a.8.8 0 00.533-.134A.8.8 0 009.333 6a2.122 2.122 0 00-.667-1.333 5.515 5.515 0 00.667-2.533.287.287 0 00-.267-.267A4.122 4.122 0 008 1.6a6.67 6.67 0 00-3.467 1.067 3.357 3.357 0 00-1.2 2.667A3.414 3.414 0 004.4 7.867a3.643 3.643 0 002.533 1.066v.533a1.712 1.712 0 00.534 1.334 1.942 1.942 0 001.2.8V14c0 .133 0 .133.133.267s.133.133.267.133z"
        />
      )
    case 'youtube':
      return (
        <path
          fill={fill}
          d="M10,16.5V7.5L16,12M20,4.4C19.4,4.2 15.7,4 12,4C8.3,4 4.6,4.19 4,4.38C2.44,4.9 2,8.4 2,12C2,15.59 2.44,19.1 4,19.61C4.6,19.81 8.3,20 12,20C15.7,20 19.4,19.81 20,19.61C21.56,19.1 22,15.59 22,12C22,8.4 21.56,4.91 20,4.4Z"
        />
      )
    case 'link':
      return (
        <g
          transform="translate(-4382.654 -653.007)"
          style={{ filter: 'url(#shadow)' }}
        >
          <path
            d="M4391.74,669.712l-2.583,2.582a2.739,2.739,0,0,1-3.875-3.873l5.166-5.166a2.738,2.738,0,0,1,3.874,0,.914.914,0,0,0,1.292-1.292,4.565,4.565,0,0,0-6.457,0l-5.166,5.166a4.566,4.566,0,0,0,6.457,6.457l2.583-2.583a.913.913,0,0,0-1.291-1.291Z"
            fill={fill}
          />
          <path
            d="M4403.233,654.344a4.567,4.567,0,0,0-6.458,0l-3.1,3.1a.913.913,0,0,0,1.291,1.291l3.1-3.1a2.739,2.739,0,1,1,3.874,3.873l-5.682,5.682a2.737,2.737,0,0,1-3.873,0,.914.914,0,0,0-1.292,1.292,4.565,4.565,0,0,0,6.457,0l5.682-5.682A4.566,4.566,0,0,0,4403.233,654.344Z"
            fill={fill}
          />
        </g>
      )
    case 'email':
      return (
        <g
          transform="translate(-4328.646 -655.583)"
          style={{ filter: 'url(#shadow)' }}
        >
          <path
            d="M4349.285,657.063c0-.023.016-.046.015-.069l-6.3,6.073,6.295,5.879c0-.042-.007-.085-.007-.128V657.063Z"
            fill={fill}
          />
          <path
            d="M4342.035,664l-2.573,2.476a.672.672,0,0,1-.925.007l-2.566-2.391-6.337,6.107a1.452,1.452,0,0,0,.493.1h17.737a1.468,1.468,0,0,0,.708-.2Z"
            fill={fill}
          />
          <path
            d="M4338.988,665.065l9.619-9.266a1.46,1.46,0,0,0-.743-.216h-17.737a1.458,1.458,0,0,0-.93.347Z"
            fill={fill}
          />
          <path
            d="M4328.646,657.289v11.529a1.46,1.46,0,0,0,.064.382l6.258-6.026Z"
            fill={fill}
          />
        </g>
      )
    case 'padlock':
      return (
        <path
          fill={fill}
          d="m3,9h1V6a5,5 0 0,1 12,0V9h1v11H3M14,9V6a4,4 0 1,0-8,0v3"
        />
      )
    default:
      return null
  }
}

const Icon = ({
  viewBox = '0 0 16 16',
  onClick,
  preserveAspectRatio = 'xMidYMid meet',
  hoverColor,
  glyph,
  alt,
  fill = 'currentColor',
  height = 16,
  width = 16,
  bg,
  circle,
  margin = '.5rem',
  title,
}) => {
  return (
    <>
      <StyledSvgContainer
        onClick={onClick}
        title={title}
        height={height}
        width={width}
        margin={margin}
        bg={bg}
        borderRadius={circle && '50px'}
      >
        <InlineSvg
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="1.414"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          viewBox={viewBox}
          preserveAspectRatio={preserveAspectRatio}
          fit
          hover={hoverColor}
          fill={fill} // take care of fill here to allow for easy hover state
          alt={alt}
          id={glyph}
          height={height}
          width={width}
        >
          <defs>
            <filter id="shadow">
              <feDropShadow dx="0.4" dy="0.6" stdDeviation="0.2" />
            </filter>
          </defs>
          <title id="title">{title || glyph}</title>
          <Glyph glyph={glyph} />
        </InlineSvg>
      </StyledSvgContainer>
    </>
  )
}

InlineSvg.propTypes = {
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  id: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
}

export default Icon
