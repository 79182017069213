import React from 'react'
import PropTypes from 'prop-types'
import FooterItem from './FooterItem'
import { StyledContainer } from '../styles'

const Copyright = ({ copyright, ...rest }) => {
  const scrollToTop = e => {
    if (e.key && e.key !== 'Enter') return
    window.scrollTo({ top, behavior: 'smooth' })
  }

  return (
    <StyledContainer
      onClick={scrollToTop}
      cursor="pointer"
      bg="#2C2C2B"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <StyledContainer
        px={4}
        color="white"
        tabIndex="0"
        onKeyDown={scrollToTop}
      >
        <FooterItem title={`© ${copyright}`} />
      </StyledContainer>
    </StyledContainer>
  )
}

Copyright.propTypes = {
  copyright: PropTypes.string,
}

export default Copyright
