// Reference to how theme object interacts with styled system
// https://styled-system.com/api
// https://styled-system.com/guides/why-powers-of-two/

import { transparentize } from 'polished'

const white = '#ffffff'
const yellow50 = '#F2EB73'
const yellowBright = '#F0E30F'
const yellowGolden = '#FFED00'
const magenta = '#F215A1'
const greyLight = '#E5E5E5'
const grey50 = '#5A5A5A'
const grey60 = '#a2a2a2'
const black = '#2C2C2B'
const darkestBlack = '#000000'
const alert = '#D0342C'

const blue = '#2699FB'
const blue50 = '#F1F9FF'
const blue75 = '#BCE0FD'

const myColorYellow = '#fbe002'
const myColorBlue = '#2480c2'
const myColorRed = '#d22027'
const myColorGreen = '#219962'
const myColorPurple = '#654587'

export const breakpoints = ['40em', '52em', '64em', '70em', '82em', '1130px']
export const fontSizes = [10, 12, 15, 18, 20, 24, 30, 38, 48, 60, 100]
export const lineHeights = [1, 3, 1.6, 1.6, 1.2, 1.1, 1.2, 1.2]
export const fontWeights = [300, 400, 400, 300]

export const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const heroColor = '#F0E30F'
const SpaceCampTheme = {
  colors: {
    white,
    yellow50,
    yellowBright,
    yellowGolden,
    greyLight,
    grey50,
    black,
    darkestBlack,
    blue,
    blue50,
    blue75,
    grey60,
    magenta,
    heroColor,
    dark: {
      socialBtnActive: magenta,
      personBio: black,
      bannerHeader: white,
      footerText: white,
      bg: darkestBlack,
      bgAlt: grey50,
      bgAltLight: greyLight,
      bgInvert: white,
      text: white,
      textAlt: grey50,
      textInvert: darkestBlack,
      indexBtnColor: white,
      indexBtnHoverColor: white,
      ctaBtnBg: yellowBright,
      ctaBtnHover: white,
      ctaBtnColor: darkestBlack,
      ctaBtnHoverColor: white,
      formAsideColor: white,
      formAsideHover: heroColor,
      formFocus: white,
      heroAlt: heroColor,
      heroAltLight: white,
      border: darkestBlack,
      borderAlt: grey50,
      peripheralText: grey60,
      btnBg: darkestBlack,
      btnHover: white,
      btnPressed: white,
      btnDisabled: grey50,
      btnInvert: darkestBlack,
      carouselDots: white,
      progressBar: grey60,
      progressBarBg: greyLight,
      progressBarText: white,
      carouselButton: grey60,
      indexBtnBg: darkestBlack,
      indexBtnHover: darkestBlack,
      eyebrow: magenta,
      carouselButtonHover: white,
      alert: alert,
    },
    hero: {
      socialBtnActive: magenta,
      personBio: grey50,
      bannerHeader: white,
      footerText: white,
      bg: darkestBlack,
      bgAlt: grey50,
      bgAltLight: greyLight,
      bgInvert: white,
      text: white,
      textAlt: grey50,
      textInvert: darkestBlack,
      indexBtnColor: white,
      indexBtnHoverColor: white,
      ctaBtnBg: yellowBright,
      ctaBtnHover: white,
      ctaBtnColor: darkestBlack,
      ctaBtnHoverColor: white,
      formAsideColor: white,
      formAsideHover: heroColor,
      formFocus: white,
      heroAlt: heroColor,
      heroAltLight: white,
      border: darkestBlack,
      borderAlt: grey50,
      peripheralText: grey60,
      btnBg: darkestBlack,
      btnHover: white,
      btnPressed: white,
      btnDisabled: grey50,
      btnInvert: darkestBlack,
      carouselDots: white,
      progressBar: grey60,
      progressBarBg: greyLight,
      progressBarText: white,
      carouselButton: grey60,
      indexBtnBg: darkestBlack,
      indexBtnHover: white,
      eyebrow: magenta,
      carouselButtonHover: white,
      alert: alert,
    },
    light: {
      socialBtnActive: magenta,
      personBio: white,
      bannerHeader: white,
      footerText: white,
      bg: darkestBlack,
      bgAlt: grey50,
      bgAltLight: greyLight,
      bgInvert: white,
      text: white,
      textAlt: grey50,
      textInvert: darkestBlack,
      indexBtnColor: white,
      indexBtnBg: darkestBlack,
      indexBtnHover: white,
      indexBtnHoverColor: black,
      ctaBtnBg: yellowBright,
      ctaBtnHover: white,
      ctaBtnColor: darkestBlack,
      ctaBtnHoverColor: white,
      formAsideColor: white,
      formAsideHover: heroColor,
      formFocus: white,
      heroAlt: heroColor,
      heroAltLight: white,
      border: darkestBlack,
      borderAlt: grey50,
      peripheralText: grey60,
      btnBg: darkestBlack,
      btnHover: white,
      btnPressed: white,
      btnDisabled: grey50,
      btnInvert: darkestBlack,
      carouselDots: white,
      progressBar: grey60,
      progressBarBg: greyLight,
      progressBarText: white,
      carouselButton: grey60,
      eyebrow: magenta,
      carouselButtonHover: white,
      alert: alert,
    },
    myColor: {
      yellow: myColorYellow,
      blue: myColorBlue,
      red: myColorRed,
      green: myColorGreen,
      purple: myColorPurple,
      black,
    },
  },
  breakpoints,
  fontSizes,
  lineHeights,
  shadows: {
    text: '0px 2px 8px #0000004f',
    bg: '2px 2px 2px #000',
  },
  fonts: {
    body:
      "'Helvetica', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
    headings:
      "'Helvetica', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
    nav:
      "'Helvetica', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
  },
  sizes: {
    maxWidth: {
      xsmall: '30em',
      small: '40em',
      medium: '52em',
      large: '64em',
      xlarge: '1216px', // changed from 1180px to match nav
      xlargeNav: '1216px', // needed to even out evenly space logo for nav
      xxlarge: '1250px',
      xlargeCarousel: '1300px', // needed to match carousel frame and btns to navbar
      text: { xsmall: '100%', small: '91.67%', medium: '75%', large: '63%' },
    },
  },
  fontWeights: {
    light: 250,
    regular: 300,
    semiBold: 400,
    bold: 600,
  },
  space,
  transitionDuration: '0.25s',
  richtext: 'default',
  borderRadius: false,
}

export const zIndices = {
  modal: 9000,
  navigation: 1000,
}

export const transitionDuration = {
  default: '0.25s',
}

export const gutters = {
  gutterX: '18px',
  gutterY: '18px',
}

export const sizes = {
  xsmall: { min: 0, max: 574 },
  small: { min: 575, max: 764 },
  medium: { min: 765, max: 991 },
  large: { min: 992, max: 1180 },
  xlarge: { min: 1181, max: Infinity },
}

export default SpaceCampTheme
