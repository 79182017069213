import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledLink } from '../styles'
import { useLocale, useHover } from '@hooks/'
import NavigationMobileOverlay from './NavigationMobileOverlay'
import { focusParentNavItem, focusVisibleBackBtn } from '../utils'

const NavigationMobileItem = ({
  title,
  slug,
  url,
  subnavigationItems,
  anchorGroup = [],
  isLastItem = false,
  toggled = false,
  setToggled = () => {},
  activeSubnav = null,
  setActiveSubnav = () => {},
  navbarOffsetTop,
  parentNavItem,
  linkContainerStyles = {},
  linkStyles = {},
  overlayStyles = {},
  CustomSubnavComponent,
}) => {
  const { isActiveSlug } = useLocale()
  const isActive = isActiveSlug(slug)
  const [hoverRef, isHovered] = useHover()

  const isActiveSubnav = activeSubnav && activeSubnav === subnavigationItems
  const anchorTagId = anchorGroup?.[0]?.anchorTagId

  const handleClick = e => {
    if (subnavigationItems) {
      setActiveSubnav(subnavigationItems)
    } else {
      const menuBtn = document?.querySelector?.('#menuBtn')
      if (menuBtn) {
        menuBtn.click()
      }
    }
  }

  const handleNavItemKeyDown = e => {
    if (e.key === 'Enter') {
      if (subnavigationItems) {
        setActiveSubnav(subnavigationItems)
        e.preventDefault()
        setTimeout(focusVisibleBackBtn, 400)
      } else {
        const menuBtn = document?.querySelector?.('#menuBtn')
        if (menuBtn) {
          menuBtn.click()
        }
      }
    }

    if (!e.shiftKey && e.key === 'Tab' && isLastItem) {
      const skipLink = document
        ?.querySelector('#skipLink')
        ?.querySelector('button')
      const logo = document?.querySelector('#navLogo')?.querySelector('a')

      if (skipLink) {
        e.preventDefault()
        skipLink.focus()
      } else if (logo) {
        e.preventDefault()
        logo.focus()
      }
    }

    if (e.key === 'Escape') {
      setToggled(false)
      const menuBtn = document?.querySelector?.('#menuBtn')

      if (parentNavItem) {
        e.preventDefault()
        setTimeout(() => {
          focusParentNavItem(parentNavItem)
        }, 400)
      } else if (menuBtn) {
        e.preventDefault()
        menuBtn.focus()
      }
    }
  }

  // Reset all overlays on menu close
  useEffect(() => {
    if (!toggled) {
      setActiveSubnav(false)
    }
  }, [setActiveSubnav, toggled])

  const SubnavComponent = CustomSubnavComponent || NavigationMobileOverlay

  return (
    <>
      <StyledContainer
        ref={hoverRef}
        width="fit-content"
        visibility={toggled && !activeSubnav ? 'visible' : 'hidden'}
        cursor={subnavigationItems ? 'pointer' : 'default'}
        {...linkContainerStyles}
      >
        <StyledLink
          to={subnavigationItems ? null : slug}
          href={subnavigationItems ? null : url}
          anchorTagId={subnavigationItems ? null : anchorTagId}
          aria-current={isActive ? 'page' : null}
          onClick={handleClick}
          onKeyDown={handleNavItemKeyDown}
          py={2}
          my={2}
          display="inline-block"
          fontWeight={700}
          fontSize={3}
          borderBottomColor={
            isHovered || isActive
              ? linkStyles?.hoverBorderColor || 'white'
              : 'transparent'
          }
          tabIndex="0"
          className="navLink"
          {...linkStyles}
          color={
            isHovered || isActive
              ? linkStyles?.hoverColor || 'inherit'
              : linkStyles?.color || 'inherit'
          }
        >
          {title}
        </StyledLink>
      </StyledContainer>
      {subnavigationItems && (
        <SubnavComponent
          parentNavItem={{ title, slug, url, anchorGroup }}
          navItems={subnavigationItems}
          toggled={toggled && isActiveSubnav}
          setToggled={setActiveSubnav}
          navbarOffsetTop={navbarOffsetTop}
          overlayStyles={overlayStyles}
          linkContainerStyles={linkContainerStyles}
          linkStyles={linkStyles}
        />
      )}
    </>
  )
}

NavigationMobileItem.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  subnavigationItems: PropTypes.arrayOf(PropTypes.object),
  anchorGroup: PropTypes.arrayOf(
    PropTypes.shape({
      anchorTagId: PropTypes.string,
    })
  ),
  isLastItem: PropTypes.bool,
  toggled: PropTypes.bool,
  setToggled: PropTypes.func,
  activeSubnav: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool,
  ]),
  setActiveSubnav: PropTypes.func,
  navbarOffsetTop: PropTypes.number,
  parentNavItem: PropTypes.object,
}

export default NavigationMobileItem
