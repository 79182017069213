import {
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  useLayoutEffect,
  useDebugValue,
} from 'react'
import createPersistedGlobalState from './useGlobalState'

import useVideoPlayer from './useVideoPlayer.js'
import useLightMode from './useLightMode.js'
import useBrowser from './useBrowser.js'
import useMobile from './useMobile'
import useComponentTheme from './useComponentTheme.js'
import useHover from './useHover.js'
import usePreviewQuery from './usePreviewQuery.js'
import useWindowReady from './useWindowReady'
import useAcceptedTermsState from './useAcceptedTermsState'
import useLockBodyScroll from './useLockBodyScroll'
import useFullScreen from './useFullScreen'
import useOnScreen from './useOnScreen'
import { useLocale, LocaleProvider } from './useLocale'
import useCopyToClipboard from './useCopyToClipboard'
import useAxios from './useAxios'
import useMeasure from './useMeasure'
import useHideOnScroll from './useHideOnScroll'
import {
  useTranslatedTerms,
  TranslatedTermsProvider,
} from './useTranslatedTerms'
import useDebounce from './useDebounce'
import usePortal from './usePortal'
import useDelayedUnmounting from './useDelayedUnmounting'

export const useVisitedState = createPersistedGlobalState('visited')

export const useSubNavOpenState = createPersistedGlobalState('open')
export const useActiveProjectIdState = createPersistedGlobalState(
  'activeProjectId'
)

export const useSubNavIndexState = createPersistedGlobalState('subNavIndex')

export {
  useAxios,
  useCopyToClipboard,
  useVideoPlayer,
  useBrowser,
  useLightMode,
  useComponentTheme,
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  useLayoutEffect,
  useDebugValue,
  useMobile,
  useHover,
  usePreviewQuery,
  useWindowReady,
  useAcceptedTermsState,
  useLockBodyScroll,
  useFullScreen,
  useLocale,
  LocaleProvider,
  useOnScreen,
  useMeasure,
  useHideOnScroll,
  useTranslatedTerms,
  TranslatedTermsProvider,
  useDebounce,
  usePortal,
  useDelayedUnmounting,
}
