// TO ADD
import { findLastKey } from 'lodash/fp'
import { useState, useEffect, useRef } from 'react'
import videojs from 'video.js'

const useVideoPlayer = ({
  src,
  thumbnail,
  modal,
  loop = false,
  autoplay = false,
  aspectRatio = '16:9',
  hideControlBar = false,
  muted = false,
  showControls,
}) => {
  const videoRef = useRef(null)
  const [player, setPlayer] = useState(null)

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      loop,
      autoplay,
      muted,
      controls: !loop || showControls,
      aspectRatio: aspectRatio || '16:9',
      controlBar: hideControlBar
        ? {
            playToggle: false,
            captionsButton: false,
            chaptersButton: false,
            subtitlesButton: false,
            remainingTimeDisplay: false,
            progressControl: {
              seekBar: true,
            },
            fullscreenToggle: false,
            playbackRateMenuButton: false,
          }
        : {
            captionsButton: true,
            pictureInPictureToggle: false,
            subsCapsButton: true,
          },
      poster: thumbnail,

      sources: [
        {
          src: src,
          type: 'video/mp4',
        },
      ],
      userActions: modal && {
        hotkeys: e => {
          const { modalRef, closePortal } = modal || {}
          const focusableModalElements = 'a, button, [role=button], iframe'

          const modalElements = Array.from(
            modalRef.current.querySelectorAll(focusableModalElements)
          ).filter(m => m.offsetParent)

          const firstButton = modalElements[0]
          const lastButton = modalElements[modalElements.length - 1]

          if (e.code === 'Tab' || e.which === 9 || e.keyCode === 9) {
            if (!e.shiftKey && e.target === lastButton) {
              e.preventDefault()
              firstButton.focus()
            }
          }

          if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
            closePortal()
          }
        },
      },
    })

    setPlayer(vjsPlayer)

    return () => {
      if (player) {
        player.dispose()
      }
    }
  }, [])

  return {
    videoRef,
    player,
  }
}

export default useVideoPlayer
