import React from 'react'
import css from '@styled-system/css'
import { useButton } from '@react-aria/button'
import Icon from '@components/Icon'
import InternalLink from './InternalLink'
import {
  BaseButton,
  BaseA,
  InternalASpan,
  InternalAPointerSpan,
  InternalAUnstyled,
  HyperlinkSpan,
  StyledDiv,
} from './styles'

const ButtonAccessibleWrapper = props => {
  let ref = React.useRef()
  let { buttonProps } = useButton(props, ref)
  let { children, ...styles } = props
  return (
    <BaseButton {...buttonProps} ref={ref} {...styles}>
      {children}
    </BaseButton>
  )
}

const ButtonAccessible = ({
  children,
  disabled,
  onClick = null,
  display,
  bg,
  color,
  hover,
  height,
  minWidth,
  letterSpacing,
  fontWeight,
  m,
  px,
  hoverBg,
  activeBg,
  activeColor,
  ...rest
}) => {
  return (
    <ButtonAccessibleWrapper
      onClick={onClick}
      {...rest}
      display={display || 'inline-block'}
      bg={!disabled ? bg || 'white' : 'greyLight'}
      color={!disabled ? color || 'darkestBlack' : 'grey60'}
      css={css({
        '&:hover': {
          color: !disabled && !hover ? null : hover,
          bg: !disabled && (hoverBg || 'white'),
        },
        '&:active': {
          color: !disabled && (activeColor || hover || color),
          bg: !disabled && (activeBg || hoverBg || bg),
        },
      })}
      height={height || '35px'}
      minWidth={minWidth || '168px'}
      letterSpacing={letterSpacing || '4px'}
      fontWeight={fontWeight || '700'}
      m={m || 2}
      px={px || 4}
      disabled={disabled}
    >
      {children}
    </ButtonAccessibleWrapper>
  )
}

const BorderButtonAccessible = ({
  children,
  disabled,
  onClick = null,
  bg,
  color,
  hover,
  height,
  minWidth,
  letterSpacing,
  fontWeight,
  m,
  px,
  ...rest
}) => {
  return (
    <ButtonAccessibleWrapper
      onClick={onClick}
      {...rest}
      border="1px solid"
      bg={!disabled ? bg || 'darkestBlack' : 'greyLight'}
      color={!disabled ? color || 'white' : 'grey60'}
      css={css({
        '&:hover': {
          color: !disabled && !hover ? null : hover,
          bg: !disabled && 'darkestBlack',
        },
        '&:active': {
          color: !disabled && !hover ? null : hover,
          bg: !disabled && 'darkestBlack',
        },
      })}
      height={height || '35px'}
      minWidth={minWidth || '168px'}
      letterSpacing={letterSpacing || '4px'}
      fontWeight={fontWeight || '700'}
      m={m || 2}
      px={px || 4}
      borderColor={!disabled ? 'white' : 'white'}
      disabled={disabled}
    >
      {children}
    </ButtonAccessibleWrapper>
  )
}

const IconButtonAccessible = ({
  children,
  link,
  glyph = '',
  viewBox = '0 0 20 20',
  icon = '',
  circle,
  fill = '',
  bg = '',
  alt = 'button',
  openPortal = null,
  ...rest
}) => {
  return children ? (
    <ButtonAccessibleWrapper
      p="5px 10px 5px 0"
      fontWeight="bold"
      bg="magenta"
      onClick={openPortal}
      {...rest}
    >
      <Icon
        glyph={glyph}
        aria-hidden="true"
        aria-label={`${glyph}${alt}`}
        height={32}
        width={32}
        fill={fill}
        alt={`${glyph}${alt}`}
        viewBox={viewBox}
      />
      {children}
    </ButtonAccessibleWrapper>
  ) : (
    <Icon
      glyph={glyph}
      aria-hidden="true"
      aria-label={`${glyph}${alt}`}
      height={glyph === 'play' ? 70 : 40}
      width={glyph === 'play' ? 70 : 40}
      bg={glyph !== 'play' && (fill === 'white' ? 'magenta' : 'white')}
      fill={fill}
      circle={circle}
      alt={`${glyph}${alt}`}
      viewBox={viewBox}
    />
  )
}

const LabelButtonAccessible = ({
  children,
  link,
  glyph,
  icon,
  border,
  alt = 'button',
  openPortal = null,
  ...rest
}) => {
  return (
    <ButtonAccessibleWrapper
      onClick={openPortal}
      border="2px solid"
      bg="white"
      hover="grey50"
      color="grey50"
      letterSpacing="2.5px"
      p="5px 10px 5px 10px"
      m=".5rem"
      fontSize={[0, 1]}
      fontWeight="normal"
      {...rest}
    >
      {children}
    </ButtonAccessibleWrapper>
  )
}

/**
TODO
 */
const NakedA = ({ children, ...styles }) => {
  const { display, fontWeight, fontSize, color, hover } = styles

  return (
    <InternalASpan
      {...styles}
      display={display || 'inline-block'}
      fontWeight={fontWeight || 'bold'}
      fontSize={fontSize || [3]}
      color={color || 'white'}
      hover={hover || 'white'}
    >
      {children}
      <InternalAPointerSpan fontSize=".75em" ml="5px">
        &#x25b6;
      </InternalAPointerSpan>
    </InternalASpan>
  )
}

const DivA = ({ children, ...styles }) => {
  return <StyledDiv {...styles}>{children}</StyledDiv>
}

const LogoA = ({ children, ...styles }) => {
  return <InternalAUnstyled {...styles}>{children}</InternalAUnstyled>
}

const HyperlinkUI = ({ children, ...styles }) => {
  return (
    <HyperlinkSpan
      color={process.env.GATSBY_SPACECAMP ? 'yellow' : 'heroNavColor'}
    >
      {children}
    </HyperlinkSpan>
  )
}

export const handleButtonOrLinkWrapping = (
  Component,
  props,
  optionalChildren
) => {
  const {
    href,
    anchorTagId,
    to,
    target,
    children,
    disabled,
    isLoading,
    tabIndex,
    onKeyDown,
    onFocus,
    mb,
    state,
    ...rest
  } = props

  const ui = (
    <Component
      disabled={disabled || isLoading}
      tabIndex={!href && !to && !anchorTagId ? tabIndex : null}
      onKeyDown={onKeyDown}
      {...rest}
    >
      {children}
    </Component>
  )

  let sanitizedHref = href
  const urlPattern = /^((http|https):\/\/)/
  const phoneOrEmailPattern = /^((mailto|tel|Tel):)/
  if (
    !urlPattern.test(sanitizedHref) &&
    !phoneOrEmailPattern.test(sanitizedHref)
  ) {
    sanitizedHref = 'https://' + sanitizedHref
  }

  if (!props.children && !optionalChildren) return null

  if (disabled) {
    return ui
  }

  if (href) {
    return (
      <BaseA
        href={sanitizedHref}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
        data-testid="external-link"
        role="link"
        width="fit-content"
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      >
        {ui}
      </BaseA>
    )
  }

  if (to || anchorTagId) {
    return (
      <InternalLink
        to={to}
        anchorTagId={anchorTagId}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        state={state}
      >
        {ui}
      </InternalLink>
    )
  }

  return ui
}

export const handleButtonOrLinkWrappingOnlyChildren = props => {
  const {
    href,
    anchorTagId,
    to,
    target,
    children,
    disabled,
    isLoading,
    tabIndex,
    onKeyDown,
    onFocus,
    mb,
    state,
    ...rest
  } = props

  let sanitizedHref = href
  const urlPattern = /^((http|https):\/\/)/
  const phoneOrEmailPattern = /^((mailto|tel|Tel):)/
  if (
    !urlPattern.test(sanitizedHref) &&
    !phoneOrEmailPattern.test(sanitizedHref)
  ) {
    sanitizedHref = 'https://' + sanitizedHref
  }

  if (!props.children) return null

  if (disabled) {
    return children
  }

  if (href) {
    return (
      <BaseA
        href={sanitizedHref}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
        data-testid="external-link"
        role="link"
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        width="auto"
        {...rest}
      >
        {children}
      </BaseA>
    )
  }

  if (to || anchorTagId) {
    return (
      <InternalLink
        to={to}
        anchorTagId={anchorTagId}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        width="auto"
        state={state}
        {...rest}
      >
        {children}
      </InternalLink>
    )
  }

  return children
}

export const RichtextHyperlink = props =>
  handleButtonOrLinkWrapping(HyperlinkUI, props)

export const LinkWrapper = props =>
  handleButtonOrLinkWrappingOnlyChildren(props)

export const LinkDiv = props => handleButtonOrLinkWrapping(DivA, props)

export const A = props => handleButtonOrLinkWrapping(NakedA, props)

export const Logo = props => handleButtonOrLinkWrapping(LogoA, props)

export const Button = props =>
  handleButtonOrLinkWrapping(ButtonAccessible, props)

export const BorderButton = props =>
  handleButtonOrLinkWrapping(BorderButtonAccessible, props)

export const LabelButton = props =>
  handleButtonOrLinkWrapping(LabelButtonAccessible, props)

export const IconButton = props =>
  handleButtonOrLinkWrapping(IconButtonAccessible, props, true)

export default Button
