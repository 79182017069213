import React, { useContext } from 'react'
import { TermsContext } from '@context/'
import { useLocale } from './useLocale'

export const useTranslatedTerms = () => {
  return useContext(TermsContext)
}

export const TranslatedTermsProvider = ({ terms, children }) => {
  const { locale, defaultLocale } = useLocale()

  const getTranslatedTerm = key => {
    return terms?.[key]?.[locale] || terms?.[key]?.[defaultLocale]
  }

  return (
    <TermsContext.Provider value={{ terms, getTranslatedTerm }}>
      {children}
    </TermsContext.Provider>
  )
}
