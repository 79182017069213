import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NavbarWrapper from '../components/NavbarWrapper'
import NavLogo from '../components/NavLogo'
import SkipLink from '../components/SkipLink'
import { useHideOnScroll, useMeasure, useDebounce } from '@hooks/'
import { StyledListItem, StyledNavList } from '../styles'
import NavigationDesktopItem from './NavigationDesktopItem'
import { getOffscreenAmount } from '../utils'
import theme from '@theme'

const NavigationDesktop = ({ navItems, logo, secondaryLogo, homeSlug }) => {
  const [toggled, setToggled] = useState(false)
  const [shouldHideNavbar, forceShowNavbar] = useHideOnScroll()
  const { ref, height } = useMeasure()

  const defaultPadding = 16
  const [navListPaddingRight, setNavListPaddingRight] = useState(defaultPadding)

  const updatePaddingOffset = () => {
    const navRef = document.querySelector('nav')
    const offsetAmount = getOffscreenAmount('.navLink', navRef)
    setNavListPaddingRight(Math.max(offsetAmount, defaultPadding))
  }

  const handleResize = useDebounce(updatePaddingOffset, 300)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <NavbarWrapper
      pushContentDown={!process.env.GATSBY_SPACECAMP}
      shouldHide={shouldHideNavbar}
      ref={ref}
      height={height}
    >
      <SkipLink
        toggled={toggled}
        setToggled={setToggled}
        forceShowNavbar={forceShowNavbar}
      />
      <NavLogo
        logo={logo}
        secondaryLogo={secondaryLogo}
        homeSlug={homeSlug}
        toggled={toggled}
        forceShowNavbar={forceShowNavbar}
      />
      {navItems && (
        <StyledNavList
          display="flex"
          alightItems="center"
          height="fit-content"
          pr={`${navListPaddingRight}px`}
        >
          {navItems?.map(({ id, navigationLink, ...rest }) => (
            <StyledListItem key={id}>
              <NavigationDesktopItem
                {...navigationLink}
                {...rest}
                navbarOffsetTop={height}
                forceShowNavbar={forceShowNavbar}
                subNavContainerStyles={
                  process.env.GATSBY_SPACECAMP
                    ? {}
                    : {
                        paddingY: '25px',
                      }
                }
                subNavLinkStyles={
                  process.env.GATSBY_SPACECAMP
                    ? {
                        hoverColor: theme.colors.heroColor,
                        hoverBorderColor: theme.colors.heroColor,
                        paddingY: '0px',
                        fontSize: '24px',
                      }
                    : {
                        hoverColor: theme.colors.black,
                        hoverBorderColor: theme.colors.heroNavColor,
                        paddingY: '8px',
                        // navbarOffsetTop: '10px'
                        // paddingY: '14px',
                        fontSize: '18px',
                      }
                }
                linkStyles={
                  process.env.GATSBY_SPACECAMP
                    ? {
                        hoverColor: theme.colors.heroColor,
                        hoverBorderColor: theme.colors.heroColor,
                        paddingY: '0px',
                        fontSize: '24px',
                      }
                    : {
                        hoverColor: theme.colors.heroNavColor,
                        hoverBorderColor: theme.colors.heroNavColor,
                        paddingY: '4px',
                        // navbarOffsetTop: '10px'
                        // paddingY: '14px',
                        fontSize: '24px',
                      }
                }
              />
            </StyledListItem>
          ))}
        </StyledNavList>
      )}
    </NavbarWrapper>
  )
}

NavigationDesktop.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.object,
  secondaryLogo: PropTypes.object,
  homeSlug: PropTypes.string,
}

export default NavigationDesktop
