import React from 'react'

const MobileContext = React.createContext()
const PreviewContext = React.createContext()
const PreviewAssetsContext = React.createContext()
const CognitoContext = React.createContext()

const AuthenticationContext = React.createContext()
const NProgressContext = React.createContext()
const SubnavContext = React.createContext()
const BladeIntersectionContext = React.createContext()
const LocaleContext = React.createContext()
const TermsContext = React.createContext()

export {
  MobileContext,
  PreviewContext,
  PreviewAssetsContext,
  CognitoContext,
  AuthenticationContext,
  NProgressContext,
  SubnavContext,
  BladeIntersectionContext,
  LocaleContext,
  TermsContext,
}
