import React from 'react'
import {
  FooterSpacecampGatsbyCDAContainer,
  FooterAyzGatsbyCDAContainer,
  FooterApolloCPAContainer,
} from '@containers/Footer'

const Footer = ({ preview, ...rest }) => {
  const FooterGatsbyCDAContainer = process.env.GATSBY_SPACECAMP
    ? FooterSpacecampGatsbyCDAContainer
    : FooterAyzGatsbyCDAContainer

  const FooterContainer = preview
    ? FooterApolloCPAContainer
    : FooterGatsbyCDAContainer

  return <FooterContainer {...rest} />
}

export default Footer
