import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledNavContainer } from '../styles'
import { useLightMode, useBrowser } from '@hooks/'

const NavbarWrapper = (
  {
    shouldHide = false,
    children,
    height = 0,
    backgroundStyles = {},
    containerStyles = {},
    pushContentDown = false,
  },
  ref
) => {
  const [isLightMode] = useLightMode()
  const theme = isLightMode ? 'light' : 'dark'
  const { browser } = useBrowser()
  const navBG = process.env.GATSBY_SPACECAMP ? 'transparent' : 'white'

  return (
    <>
      <StyledNavContainer
        ref={ref}
        aria-label="Main"
        display="flex"
        position="fixed"
        zIndex={5000}
        width="100%"
        minHeight="100px"
        backgroundColor={navBG}
        hide={shouldHide}
        {...backgroundStyles}
      >
        <StyledContainer
          margin="0 auto"
          width={[
            'maxWidth.xsmall',
            'maxWidth.small',
            'maxWidth.medium',
            'maxWidth.large',
            'maxWidth.xlarge',
            'maxWidth.xxlarge',
          ]}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={[3, 3, 0]}
          px={[3, 3, 0]}
          {...containerStyles}
          backdropFilter={
            browser === 'Safari' && backgroundStyles?.backdropFilter
          }
        >
          {children}
        </StyledContainer>
      </StyledNavContainer>
      {pushContentDown && <StyledContainer height={height} />}
    </>
  )
}

export default React.forwardRef(NavbarWrapper)
