import React from 'react'
import PropTypes from 'prop-types'
import Button from '@components/Button'
import { useTranslatedTerms } from '@hooks/'
import { StyledSkipToContent } from '../styles'
import { focusLastNavItem } from '../utils'

const SkipLink = ({
  toggled = false,
  setToggled = () => {},
  forceShowNavbar = () => {},
  containerStyles = {},
  buttonStyles = {},
  onClick,
  onKeyDown,
  onFocus,
  children,
}) => {
  const { getTranslatedTerm } = useTranslatedTerms()

  const defaultHandleClick = () => setToggled(false)
  const defaultHandleKeyDown = e => {
    if (e.shiftKey && e.key === 'Tab') {
      focusLastNavItem({ e, toggled })
    }
    if (e.key === 'Enter' || e.key === 'Escape') {
      setToggled(false)
    }
  }

  return (
    <StyledSkipToContent
      id="skipLink"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      onClick={onClick || defaultHandleClick}
      onKeyDown={onKeyDown || defaultHandleKeyDown}
      onFocus={onFocus || forceShowNavbar}
      {...containerStyles}
    >
      <Button
        tabIndex="-1"
        anchorTagId="main"
        height="40px"
        p="0"
        m="0"
        bg="darkestBlack"
        color="white"
        letterSpacing="2px"
        {...buttonStyles}
      >
        {children || getTranslatedTerm('skipLink')}
      </Button>
    </StyledSkipToContent>
  )
}

SkipLink.propTypes = {
  toggled: PropTypes.bool,
  setToggled: PropTypes.func,
  forceShowNavbar: PropTypes.func,
  containerStyles: PropTypes.object,
  buttonStyles: PropTypes.object,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

export default SkipLink
