import React from 'react'
import PropTypes from 'prop-types'
import { StyledArrow, StyledContainer, StyledText } from '../styles'
import { useTranslatedTerms } from '@hooks/'
import { focusParentNavItem } from '../utils'

const BackBtn = ({
  toggled = false,
  setToggled = () => {},
  activeSubnav,
  parentNavItem,
  onKeyDown,
  onFocus,
  onClick,
  children,
}) => {
  const { getTranslatedTerm } = useTranslatedTerms()

  const handleClick = () => setToggled(false)
  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setToggled(false)
      e.preventDefault()
      setTimeout(() => {
        focusParentNavItem(parentNavItem)
      }, 400)
    }
  }

  return (
    <StyledContainer
      width={[
        'maxWidth.xsmall',
        'maxWidth.small',
        'maxWidth.medium',
        'maxWidth.large',
        'maxWidth.xlarge',
        'maxWidth.xxlarge',
      ]}
      margin="0 auto"
      pl={3}
      pt={3}
    >
      <StyledContainer
        className="backBtn"
        role="button"
        aria-label="back button"
        tabIndex="0"
        width="fit-content"
        focusedOutline
        visibility={toggled && !activeSubnav ? 'visible' : 'hidden'}
        onClick={onClick || handleClick}
        onKeyDown={onKeyDown || handleKeyDown}
        onFocus={onFocus}
      >
        <StyledContainer
          mt={3}
          cursor="pointer"
          display="flex"
          alignItems="center"
          width="fit-content"
          tabIndex="-1"
        >
          <StyledArrow
            width="11px"
            height="11px"
            borderRadius="2px"
            borderRight="3px solid"
            borderTop="3px solid"
          />
          <StyledText fontSize={4} fontWeight="700" p={1}>
            {children || getTranslatedTerm('backButton')}
          </StyledText>
        </StyledContainer>
      </StyledContainer>
    </StyledContainer>
  )
}

BackBtn.propTypes = {
  toggled: PropTypes.bool,
  setToggled: PropTypes.func,
  activeSubnav: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool,
  ]),
  parentNavItem: PropTypes.object,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

export default BackBtn
