import React, { useState } from 'react'
import PropTypes from 'prop-types'
import NavbarWrapper from '../components/NavbarWrapper'
import NavLogo from '../components/NavLogo'
import NavMenuBtn from '../components/NavMenuBtn'
import SkipLink from '../components/SkipLink'
import NavigationMobileOverlay from './NavigationMobileOverlay'
import { useHideOnScroll, useMeasure, useLockBodyScroll } from '@hooks/'

const NavigationMobile = ({ navItems, logo, secondaryLogo, homeSlug }) => {
  const [toggled, setToggled] = useState(false)
  const [shouldHideNavbar, forceShowNavbar] = useHideOnScroll()
  const { ref, height } = useMeasure({ initHeight: 100 })

  useLockBodyScroll(toggled)

  return (
    <NavbarWrapper
      pushContentDown
      shouldHide={shouldHideNavbar}
      ref={ref}
      height={height}
    >
      <SkipLink
        toggled={toggled}
        setToggled={setToggled}
        forceShowNavbar={forceShowNavbar}
      />
      <NavLogo
        logo={logo}
        secondaryLogo={secondaryLogo}
        homeSlug={homeSlug}
        toggled={toggled}
        forceShowNavbar={forceShowNavbar}
      />
      {navItems && (
        <NavMenuBtn
          toggled={toggled}
          setToggled={setToggled}
          forceShowNavbar={forceShowNavbar}
        />
      )}
      {navItems && (
        <NavigationMobileOverlay
          navItems={navItems}
          toggled={toggled}
          setToggled={setToggled}
          navbarOffsetTop={height}
        />
      )}
    </NavbarWrapper>
  )
}

NavigationMobile.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.object,
  secondaryLogo: PropTypes.object,
  homeSlug: PropTypes.string,
}

export default NavigationMobile
