import { useRef } from 'react'
const useDebounce = (fn, delay = 500) => {
  const timer = useRef()

  return () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(fn, delay)
  }
}

export default useDebounce
