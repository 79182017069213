import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

import { handleButtonOrLinkWrapping } from '@components/Button'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  a {
    text-decoration: none;
  }

  ${({ cursor }) => ({ cursor })}
`
export const StyledFooterContainer = styled.footer`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`
export const StyledNavContainer = styled.nav`
  transition: transform 1s;
  ${({ shrink }) =>
    shrink ? `transform: translateY(-100%);` : 'transform: translateY(0%);'}
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledImg = styled.img`
  color: inherit;

  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledList = styled.ul`
  margin: 5rem;
  ${space}
  ${flexbox}
  ${layout}
`

export const StyledListItem = styled.li`
  ${space}
  ${flexbox}

  a {
    text-decoration: none;
  }
`

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
  ${space} 
  ${typography}
  ${color}
`

export const NavLink = styled.div`
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  ${space}
  ${color}
  ${typography}
  ${border}
`

export const FooterContainer = styled.footer`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const FooterColumn = styled.div`
  white-space: nowrap;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
   ${({ cursor }) => ({ cursor })}
`

export const FooterLinksWrapper = styled.div`
box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const FooterTitle = styled.p`
  margin: 0;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const FooterFormColumnTitle = styled.h1`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const FooterSubLinkStyle = styled.div`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  cursor: pointer;
`

export const StyledCopyRight = styled.div`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  padding:20px 55px;
`

export const StyledNavLink = props => handleButtonOrLinkWrapping(NavLink, props)
