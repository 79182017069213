import React from 'react'
import PropTypes from 'prop-types'
import { useMobile } from '@hooks/'
import { NavigationDesktop } from './NavigationDesktop'
import { NavigationMobile } from './NavigationMobile'

const NavigationLayout = ({ mobileOnly = false, ...rest }) => {
  const [isMobile] = useMobile()

  if (isMobile === undefined) return null // isMobile === undefined for SSR
  if (!mobileOnly && isMobile === false) return <NavigationDesktop {...rest} />
  return <NavigationMobile {...rest} />
}

NavigationLayout.propTypes = {
  mobileOnly: PropTypes.bool,
}

export default NavigationLayout
