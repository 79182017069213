// import React from 'react'
import createPersistedGlobalState from './useGlobalState'

const useLightModeState = createPersistedGlobalState('isLightMode')

const useLightMode = () => {
  const [isLightMode, setIsLightMode] = useLightModeState(true)
  const toggleLightMode = () => {
    setIsLightMode(!isLightMode)
  }
  return [isLightMode, toggleLightMode]
}
export default useLightMode
