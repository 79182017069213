export const translatedJSON = {
  ctaArticle: {
    'en-US': 'Read More',
  },
  ctaDefault: {
    'en-US': 'Learn More',
  },
  byTranslate: {
    'en-US': 'BY',
  },
  skipLink: {
    'en-US': 'Skip to Content',
  },
  backButton: {
    'en-US': 'Back',
  },
  home: {
    'en-US': 'home',
  },
  loadMoreButton: {
    'en-US': 'Load More',
  },
  ourPeopleTranslate: {
    'en-US': 'Our People',
  },
  previous: {
    'en-US': 'Previous',
  },
  next: {
    'en-US': 'Next',
  },
  insights: {
    'en-US': 'Insights',
  },
}
