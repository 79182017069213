import React from 'react'
import PropTypes from 'prop-types'
import FooterItem from './FooterItem'
import { useMobile } from '@hooks'
import { FooterLinksWrapper, FooterColumn, StyledContainer } from '../styles'

const FooterDesktop = ({
  navItems,
  copyright = '',
  theme = 'light',
  ...rest
}) => {
  const [isMobile] = useMobile('27em')
  const year = new Date().getFullYear()
  const scrollToTop = () => window.scrollTo({ top, behavior: 'smooth' })

  return (
    <StyledContainer
      color={`${theme}.footerText`}
      bg="#2C2C2B"
      mt={process.env.GATSBY_SPACECAMP && ['40px', 6]}
      pt={[1]}
    >
      <StyledContainer
        display="flex"
        flexDirection={['column', 'column', 'column', 'row', 'row', 'row']}
        justifyContent="center"
        alignItems="center"
        margin="0 auto"
        mt={4}
        pb={4}
        maxWidth={[
          'maxWidth.xsmall',
          'maxWidth.small',
          'maxWidth.medium',
          'maxWidth.large',
          'maxWidth.xlarge',
          'maxWidth.xxlarge',
        ]}
      >
        <FooterLinksWrapper
          display="flex"
          justifyContent="center"
          flexDirection={['column', 'column', 'column', 'row', 'row', 'row']}
        >
          {!isMobile &&
            navItems?.map(({ id, ...rest }) => {
              return <FooterItem key={id} {...rest} />
            })}
          <FooterColumn onClick={scrollToTop} cursor="pointer">
            <FooterItem title={`© ${year} ${copyright}`} />
          </FooterColumn>
        </FooterLinksWrapper>
      </StyledContainer>
    </StyledContainer>
  )
}

FooterDesktop.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      navigationLink: PropTypes.shape({
        displayTextAlt: PropTypes.string,
        url: PropTypes.string,
        slug: PropTypes.string,
      }),
      subnavigationItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          navigationLink: PropTypes.shape({
            displayTextAlt: PropTypes.string,
            url: PropTypes.string,
            slug: PropTypes.string,
          }),
        })
      ),
      title: PropTypes.string,
    })
  ),
  copyright: PropTypes.string,
}

export default FooterDesktop
