import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const Seo = ({
  pageSEO = true,
  articleSEO = false,
  metaTitle = '',
  metaDescription = '',
  locale = 'en-US',
  ogImage = { fixed: { width: 1000, height: 500, src: '' } },
}) => {
  const { defaultSiteBranding: { nodes } } = useStaticQuery(graphql`
    query SeoQuery {
      defaultSiteBranding: allContentfulSiteInformationAndBranding(
        limit: 1
      ) {
        nodes {
          title
          shortTitle
          copyright
          description {
            description
          }
          ogImage {
            sourceLandscape {
              file {
                url
              }
            }
          }
          shareImage {
            sourceLandscape {
              file {
                url
              }
            }
          }
          author {
            name
            slug
            email
            facebook
            instagram
            twitter
          }
        }
      }
    }
`)
let {
  title,
  shortTitle,
  copyright,
  description: {
    description
  },
  ogImage: {
    sourceLandscape: {
      file: {
        url: ogImageUrl
      }
    }
  },
  shareImage: {
    sourceLandscape: {
      file: {
        url: shareImageUrl
      }
    }
  },
  author: {
    name: authorName,
    slug: authorSlug,
    email,
    facebook,
    instagram,
    twitter,
  }
} = nodes[0]
  const entryTitle = metaTitle || title;

  const siteUrl = 'https://www.ayzenberg.com'
  const [url, setUrl] = useState('')
  const imageWidth = '1200px'
  const imageHeight = '1200px'


  let schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': pageSEO ? 'WebPage' : 'WebSite',
      url: url,
      name: entryTitle,
      alternateName: title,
    },
  ]

  if (articleSEO) {
    schemaOrgJSONLD.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': siteUrl,
              name: title,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': url,
              name: title,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: url,
        name: title,
        alternateName: shortTitle,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: 'https:' + shareImageUrl,
          width: imageWidth,
          height: imageHeight,
        },
        author: {
          '@type': 'Person',
          name: authorName,
          url: siteUrl + authorSlug,
        },
        publisher: {
          '@type': 'Organization',
          name: shortTitle,
          url: siteUrl,
        },
        // datePublished: articleNode.publishDateISO,
        mainEntityOfPage: url,
      }
    )
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let location = window.location.origin
      setUrl(location)
    }
  }, [])

  return (
    <Helmet
      defaultTitle={metaTitle}
      title={metaTitle}
      htmlAttributes={{
        lang: locale,
      }}
    >
      {/* General tags */}
      <title>{metaTitle}</title>
      <meta name="image" content={'https://' + ogImageUrl} />
      <meta name="description" content={metaDescription} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content={articleSEO ? 'article' : 'website'} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={'https://' + ogImageUrl} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />

      <meta property="og:url" content={url} />

      {/* Social tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:image" content={'https://' + shareImageUrl} />
      <meta name="twitter:description" content={metaDescription} />

      {/* Full Screen Capabilities */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
    </Helmet>
  )
}

Seo.propTypes = {
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
}

export default Seo
