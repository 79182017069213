export const isVisible = elem =>
  elem && window.getComputedStyle(elem)?.visibility === 'visible'

export const getAllElems = (selector, rootNode = document) =>
  rootNode && [...rootNode.querySelectorAll(selector)]

export const focusLastNavItem = ({ e, toggled, selector = '.navLink' }) => {
  const logo = document?.querySelector('#navLogo')?.querySelector('a')
  const navRef = document.querySelector('nav')
  let lastNavLink = null
  let navLinks = [
    ...getAllElems('.navLink', navRef),
    ...getAllElems(selector, navRef),
  ]

  while (navLinks.length) {
    const currNavLink = navLinks.pop()

    if (isVisible(currNavLink)) {
      const closestA = currNavLink.closest('a')
      lastNavLink = closestA || currNavLink
      break
    }
  }

  if (toggled && lastNavLink && lastNavLink !== logo) {
    e.preventDefault()
    lastNavLink.focus()
  }
}

export const focusParentNavItem = parentNavItem => {
  const navRef = document.querySelector('nav')
  let lastNavLink = null
  let navLinks = getAllElems('.navLink', navRef)

  while (navLinks.length) {
    const currNavLink = navLinks.pop()
    const closestDiv = currNavLink?.closest('div')

    if (
      isVisible(closestDiv) &&
      currNavLink.innerText === parentNavItem?.title
    ) {
      const closestA = currNavLink.closest('a')
      lastNavLink = closestA || currNavLink
      break
    }
  }

  if (lastNavLink) {
    lastNavLink.focus()
  }
}

export const focusVisibleBackBtn = () => {
  const navRef = document.querySelector('nav')
  let visibleBackBtn = null
  let backBtns = getAllElems('.backBtn', navRef)

  while (backBtns.length) {
    const currNavLink = backBtns.pop()
    if (isVisible(currNavLink)) {
      visibleBackBtn = currNavLink
      break
    }
  }

  if (visibleBackBtn) {
    visibleBackBtn.focus()
  }
}

export const getOffscreenAmount = (selector, rootNode = document) => {
  const links = rootNode?.querySelectorAll(selector)
  let longestOffscreenVal = 0

  if (links) {
    links.forEach(link => {
      const rectRight = link.getBoundingClientRect().right
      if (rectRight > longestOffscreenVal) {
        longestOffscreenVal = rectRight
      }
    })
  }

  const offsetAmount = longestOffscreenVal - window.innerWidth
  return offsetAmount
}
