import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const environment = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
const accessToken = process.env.GATSBY_CONTENTFUL_PREVIEW_API

const accessPath = `access_token=${accessToken}`
const basePath = `/environments/${environment}`
const entriesPath = `${basePath}/entries`
const assetsPath = `${basePath}/assets`

const textEntry = `
query getTextEntry($id: String!) {
  entry(id: $id) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`

// preview.contentful.com/spaces/3yvivwi0yvy3/environments/development/entries?access_token=R7cvbynHdtX7qhZ5Kk4p0IM_wTzBx0Z60HLDjyZQrT8&sys.id[in]=id1,id2,id3&content_type=contact&include
const entriesByContentTypeId = `
query getentriesByContentTypeId($id: String!, $locale: String!, $limit: Int) {
  entries(id: $id, locale: $locale, limit: $limit) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type={args.id}&locale={args.locale}&limit={args.limit}&include=2") {
    items
    includes
  }
}
`

const navigationItems = `
query getNavItems($id: String!, $ids: String!) {
  entries(id: $id, ids: $ids) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type=navigation&fields.placement[match]='Projects Header'&include=2") {
    items
    includes
  }
}
`
const columns = `
query getColumns($id: String!, $ids: String!) {
  entries(id: $id, ids: $ids) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type=bladeMedia&sys.id[in]={args.ids}&include=2") {
    items
  }
}
`
const listItems = `
query getListItems($id: String!, $ids: String!, $locale: String!) {
  entries(id: $id, ids: $ids, locale: $locale) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type=listItem&sys.id[in]={args.ids}&include=2&locale={args.locale}") {
    items
    includes
  }
}
`

const entriesByContentTypeIdAndEntryIds = `
query getentriesByContentTypeIdAndEntryIds($id: String!, $ids: String!, $locale: String!) {
  entries(id: $id, ids: $ids, locale: $locale) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type={args.id}&sys.id[in]={args.ids}&include=2&locale={args.locale}") {
    items
    includes
  }
}
`

// [eq]
const navigationByPlacement = `
query getNav($id: String! $locale: String!) {
  entries(id: $id, locale: $locale) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type=moduleNavigation&fields.placement[match]={args.id}&locale={args.locale}&include=4") {
    items
    includes
  }
}
`
const entriesByContentTypeIdAndEntryIdsAndTag = `
query getentriesByContentTypeIdAndEntryIdsTag($id: String!, $ids: String!, $locale: String!, $limit: Int) {
  entries(id: $id, ids: $ids, locale: $locale,  limit: $limit) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type={args.id}&fields.tag.sys.id[in]={args.ids}&include=4&limit={args.limit}&order=-fields.publicationDate,fields.title&locale={args.locale}") {
    items
    includes
  }
}
`
const entriesByContentTypeIdAndEntryIdsAndCategory = `
query getentriesByContentTypeIdAndEntryIdsCategory($id: String!, $ids: String!,$locale: String!, $limit: Int) {
  entries(id: $id, ids: $ids,locale: $locale, limit: $limit) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type={args.id}&fields.category.sys.id[in]={args.ids}&include=4&limit={args.limit}&order=-fields.publicationDate,fields.title&locale={args.locale}") {
    items
    includes
  }
}
`
const entriesByContentTypeIdAndEntryIdsAndAuthor = `
query getentriesByContentTypeIdAndEntryIdsAuthor($id: String!, $ids: String!, $locale: String!, $limit: Int) {
  entries(id: $id, ids: $ids, locale: $locale,limit: $limit) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type={args.id}&fields.author.sys.contentType.sys.id=person&fields.author.sys.id[in]={args.ids}&include=4&limit={args.limit}&order=-fields.publicationDate,fields.title&locale={args.locale}") {
    items
    includes
  }
}
`
const entryById = `
query getEntryById($id: String!, $locale: String!) {
  entry(id: $id , locale: $locale) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&sys.id[in]={args.id}&include=4&locale={args.locale}") {
    items
    includes
  }
}
`

const entry = `
query getEntry($id: String!) {
  entry(id: $id) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`
const reel = `
query getReel($id: String!) {
  entry(id: $id) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`
const media = `
query getMedia($id: String!) {
  entry(id: $id) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`
const asset = `
query getAsset($id: String!) {
  asset(id: $id) @rest(type: "Asset", path: "${assetsPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`
const linksToEntry = `
query getLinksToEntry($id: String!) {
  links(id: $id) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&links_to_entry={args.id}") {
   items
  }
}
`
const assets = `
query getAllAssets {
  assets @rest(type: "Asset", path: "${assetsPath}?${accessPath}") {
   items
  }
}
`

export const GET_TEXT_ENTRY = gql`
  ${textEntry}
`

export const GET_LINKS_TO_ENTRY = gql`
  ${linksToEntry}
`

export const GET_ENTRY = gql`
  ${entry}
`
export const GET_REEL = gql`
  ${reel}
`
export const GET_MEDIA = gql`
  ${media}
`

export const GET_ASSET = gql`
  ${asset}
`

export const GET_ALL_ASSETS = gql`
  ${assets}
`

export const GET_ENTRIES_BY_CONTENT_TYPE_ID = gql`
  ${entriesByContentTypeId}
`

export const GET_ENTRY_BY_ID = gql`
  ${entryById}
`

export const GET_NAVIGATION_ITEMS = gql`
  ${navigationItems}
`

export const GET_LIST_ITEMS = gql`
  ${listItems}
`
export const GET_COLUMNS = gql`
  ${columns}
`
export const GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS = gql`
  ${entriesByContentTypeIdAndEntryIds}
`
export const GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_AUTHOR = gql`
  ${entriesByContentTypeIdAndEntryIdsAndAuthor}
`
export const GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_TAG = gql`
  ${entriesByContentTypeIdAndEntryIdsAndTag}
`
export const GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_CATEGORY = gql`
  ${entriesByContentTypeIdAndEntryIdsAndCategory}
`

export const GET_NAVIGATION = gql`
  ${navigationByPlacement}
`

// apollo gets buggy if you don't add ids to subqueries
const usePreviewQuery = ({
  query,
  id,
  skipBool = false,
  ids,
  locale = 'en-US',
  limit = 100,
}) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    query,
    ids
      ? {
          variables: { id, ids, locale, limit },
          skip: skipBool,
          notifyOnNetworkStatusChange: true,
          errorPolicy: 'all',
        }
      : {
          variables: { id, locale, limit },
          skip: skipBool,
          notifyOnNetworkStatusChange: true,
          errorPolicy: 'all',
        }
  )

  return { loading, error, data, refetch, networkStatus }
}

export default usePreviewQuery
