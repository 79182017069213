import { useState, useEffect, useCallback } from 'react'
import useWindowScrollPosition from './useWindowScrollPosition'

const useHideOnScroll = ({ threshold = 30 } = {}) => {
  const [hide, setHide] = useState({ val: false, offset: 0 })
  const { y } = useWindowScrollPosition({ startAtZero: true })

  const forceShow = useCallback(() => {
    setHide({ val: false, offset: y })
  }, [y])

  useEffect(() => {
    if (!y || y < threshold) {
      setHide({ val: false, offset: 0 })
    }
    if (y - hide.offset > threshold) {
      setHide({ val: true, offset: y })
    }
    if (y - hide.offset < -1 * threshold) {
      setHide({ val: false, offset: y })
    }
  }, [setHide, hide.offset, threshold, y])

  return [hide.val, forceShow]
}

export default useHideOnScroll
