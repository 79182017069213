import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  background,
} from 'styled-system'
import theme from '@theme'
import { Logo } from '@components/Button'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  transition: opacity 0.25s ease-in-out 0.05s, visibility 0.25s ease-in-out 0.05s;
  outline: none;
  
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${background}

  ${({ transform, visibility, cursor, transition }) => ({
    transform,
    visibility,
    cursor,
    transition,
  })}

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    ${({ backdropFilter }) => ({
      backdropFilter,
    })}
  }

  &:focus {
    ${({ focusedOutline }) =>
      focusedOutline && 'outline: 5px auto -webkit-focus-ring-color; '}
  }
`
export const StyledNavContainer = styled.nav`
  box-sizing: border-box;
  transition: transform .5s;
  background: rgba(0, 0, 0, 0);
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${background}
  
  ${({ transform, visibility, cursor, transition }) => ({
    transform,
    visibility,
    cursor,
    transition,
  })}

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    ${({ backdropFilter }) => ({
      backdropFilter,
    })}
  }

  ${({ hide }) =>
    hide ? `transform: translateY(-250%);` : 'transform: translateY(0%);'}
    max-width: 100vw;

`

export const StyledImg = styled.img`
  color: inherit;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledBtnBox = styled.span`
  display: inline-block;
  position: relative;
  outline: none;
  ${layout}
  ${space}
`
export const StyledMenuBtnContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  background-color: transparent;
  border: none;
  box-shadow: none;
  overflow: visible;
  outline: none;
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${position}
`

export const StyledMenuBtnLine = styled.span`
  display: block;
  margin-top: -2px;
  height: 2px;
  position: absolute;
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  ${color}
  ${layout}
  ${position}
  ${({ large, width, toggled }) =>
    large && !toggled && `width: ${width.split('px')[0] * 0.8}px;`}
  ${({ toggled }) =>
    toggled &&
    `background: ${process.env.GATSBY_SPACECAMP ? theme.colors.white : theme.colors.black};
    -webkit-transform: translate3d(0,-10px,0) rotate(-45deg);
    transform: translate3d(0,-10px,0) rotate(-45deg);
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);`}

  &::before {
    height: 2px;
    background-color: #000;
    position: absolute;
    content: '';
    display: block;
    background: inherit;
    top: ${({ large }) => (large ? '-12px' : '-8px')};
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${({ toggled }) =>
      toggled &&
      `background: ${process.env.GATSBY_SPACECAMP ? theme.colors.white : theme.colors.black};
      top: 0;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),-webkit-transform .13s .25s cubic-bezier(.215,.61,.355,1);
      transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),transform .13s .25s cubic-bezier(.215,.61,.355,1);
      transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),transform .13s .25s cubic-bezier(.215,.61,.355,1),-webkit-transform .13s .25s cubic-bezier(.215,.61,.355,1);`}
    ${layout}
    ${position}
  }

  &::after {
    height: 2px;
    background-color: #000;
    position: absolute;
    content: '';
    display: block;
    background: inherit;
    top: ${({ large }) => (large ? '-24px' : '-16px')};
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      opacity 0.1s linear;

    ${({ toggled }) =>
      toggled &&
      `background: ${theme.colors.white};
      top: 0;
      opacity: 0;
      transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s .22s linear;`}
    ${layout}
    ${position}
  }
`

export const StyledSkipToContent = styled.div`
  box-sizing: border-box;
  white-space: nowrap;
  outline: none;
  border: none;
  box-shadow: none;
  margin: 0;
  top: -100%;
  left: 0;
  transform: translateY(-100%);

  &:focus-within{
    top: 0;
    transform: translateY(0%);
  }
  
  a {
    padding: 0;
  }

  a:focus {
    z-index: 2;
    top: 0;
    transform: translateY(0%);
    background-color: black;
  }

  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

const getSlideTransform = slideDirection => {
  switch (slideDirection) {
    case 'up':
      return 'translateY(150%)'
    case 'left':
      return 'translateX(150%)'
    case 'down':
      return 'translateY(-150%)'
    case 'right':
      return 'translateX(-150%)'
    default:
      return 'translate(0,0)'
  }
}

export const StyledMobileOverlayContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.25s;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}

  ${({ transform, visibility, cursor, transition }) => ({
    transform,
    visibility,
    cursor,
    transition,
  })}

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    ${({ backdropFilter }) => ({
      backdropFilter,
    })}
  }

  ${({ toggled, slideDirection }) =>
    toggled
      ? `transform: translate(0,0);`
      : `transform: ${getSlideTransform(slideDirection)};`}  

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
  }
`

export const StyledNavList = styled.ul`
  box-sizing: border-box;
  transition: height 0.5s, padding 0.5s;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledListItem = styled.li`
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}

  ${({ transform, visibility, cursor, transition }) => ({
    transform,
    visibility,
    cursor,
    transition,
  })}

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    ${({ backdropFilter }) => ({
      backdropFilter,
    })}
  }
`

export const StyledLink = styled(Logo)`
  border-bottom: ${({ hideBottomBorder }) =>
    hideBottomBorder ? '1px solid transparent' : '3px solid transparent'};
  transition: border-bottom-color .15s ease-in-out, color .15s ease-in-out;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}

  ${({ textTransform }) => ({
    textTransform,
  })}

`

export const StyledArrow = styled.div`
  transform: rotate(-135deg);
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
  ${space}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${layout}
  ${flexbox}
`
