import React from 'react'
import PropTypes from 'prop-types'
import { useMobile } from '@hooks'
import FooterMobile from './FooterMobile'
import FooterDesktop from './FooterDesktop'

const Footer = ({ mobileOnly = false, ...rest }) => {
  const [isMobile] = useMobile()

  if (isMobile === undefined) return null // isMobile === undefined for SSR
  if (!mobileOnly && isMobile === false) return <FooterDesktop {...rest} />
  return <FooterMobile {...rest} />
}

Footer.propTypes = {
  mobileOnly: PropTypes.bool,
}

export default Footer
