import styled, { css, createGlobalStyle } from 'styled-components'
import theme from '../../themes'
import { space, layout } from 'styled-system'

export const fontStack = css`
  font-family: 'Helvetica', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-style: normal;
`

export const MaxWidthContainer = styled.div`
  ${space}
  ${layout}
`

export const GlobalStyle = createGlobalStyle`
  html {
    ${fontStack};
    width: 100%; 
    height: 100%;
    overflow-x: hidden;
  }

  body, header, footer {
    color: ${p =>
      p.theme ? theme.colors[p.theme].text : theme.colors.light.text};
    transition: background 0.2s linear, fill 0.3s linear, opacity 0.3s linear;
    background-color: ${p =>
      p.theme ? theme.colors[p.theme].bg : theme.colors.light.bg};
    line-height: 1.2;
    margin: 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  
  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    text-align: inherit;

    background: transparent;

    color: inherit;
    font: inherit;

    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
  }
    

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  textarea {
    ${fontStack};

  }

`
