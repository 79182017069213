import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles'
import Container from '@components/Container'
import Seo from '@components/Seo'
import Navigation from '@components/Navigation'
import Footer from '@components/Footer'
import theme from '../../themes'
import { useLightMode, LocaleProvider } from '@hooks'
import { PreviewContext } from '@context/index'
import { TranslatedTermsProvider } from '@hooks/useTranslatedTerms'
import { translatedJSON } from '@constants'
import { formatPreviewSlug } from '@utils/'

const Layout = ({
  preview,
  children,
  locale = 'en-US',
  noSEO,
  seo,
  ...rest
}) => {
  const [isLightMode] = useLightMode()
  const homeID =
    process.env.GATSBY_HOME_ID ||
    (process.env.GATSBY_SPACECAMP
      ? 'Ie2GXTaN0PCfAQ7Si0rTB'
      : '3TRvgqirRJRPjTOHfpwuNR')
  const homeSlug = preview ? formatPreviewSlug(homeID, 'page') : '/'

  return (
    <ThemeProvider theme={theme}>
      <LocaleProvider locale={locale}>
        <TranslatedTermsProvider terms={translatedJSON}>
          <PreviewContext.Provider value={preview}>
            {!noSEO && <Seo {...seo} locale={locale} />}
            <GlobalStyle theme={'light'} />
            <Navigation preview={preview} homeSlug={homeSlug} />
            <Container>{children}</Container>
            <Footer preview={preview} homeSlug={homeSlug} />
          </PreviewContext.Provider>
        </TranslatedTermsProvider>
      </LocaleProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  locale: PropTypes.string,
}

export default Layout
