import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
} from 'styled-system'

export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
`

export const StyledSvgContainer = styled(StyledContainer)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}
`

export const InlineSvg = styled.svg`
  color: inherit;
  fill: ${({ fill }) => fill || 'pink' || 'currentColor'};
  ${({ pointer }) => pointer && 'cursor: pointer'};
  transition: all .3s;

  &:hover {
    ${({ hover }) => ` fill: ${hover};`}
  }
  
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${layout}
  ${flexbox}
`
