import { handleRemoveContentfulFromContentTypeString } from './dataFormatting'
import { withArtDirection } from 'gatsby-plugin-image'

export const getVimeoSrc = str => {
  return str
}
export const getYoutubeEmbed = video => {
  if (video.includes('watch?v=')) {
    video = video.replace('watch?v=', 'embed/')
  }
  if (video.includes('&feature=emb_logo')) {
    video = video.replace('&feature=emb_logo', '')
  }
  return video
}

export const getExternalSourceEmbedLink = url => {
  if (url && typeof url === 'string' && url.includes('youtube')) {
    url = getYoutubeEmbed(url)
  }
  if (url && typeof url === 'string' && url.includes('vimeo')) {
    url = getVimeoSrc(url)
  }
  // going to add other external links in the future
  return url
}

export const parseVideo = (video, { isMobile, raw = false } = {}) => {
  if (!video) return null
  const {
    cloudinaryVideoSource,
    cloudinaryVideoSourcePortrait,
    externalSource,
    posterLandscape,
    posterPortrait,
    ...rest
  } = video

  if (
    !cloudinaryVideoSource &&
    !cloudinaryVideoSourcePortrait &&
    !externalSource
  )
    return undefined

  const responsiveSrcType = isMobile
    ? cloudinaryVideoSourcePortrait
    : cloudinaryVideoSource
  const fallbackSrcType = isMobile
    ? cloudinaryVideoSource
    : cloudinaryVideoSourcePortrait

  const src =
    responsiveSrcType?.[0]?.original_secure_url ||
    fallbackSrcType?.[0]?.original_secure_url

  const responsiveThumbnailType = isMobile ? posterPortrait : posterLandscape
  const fallbackThumbnailType = isMobile ? posterLandscape : posterPortrait

  const thumbnail =
    responsiveThumbnailType?.file?.url || fallbackThumbnailType?.file?.url

  return {
    ...rest,
    src,
    thumbnail,
    externalSource: getExternalSourceEmbedLink(externalSource?.url),
  }
}

export const getAspectRatio = (
  { sourceLandscape, sourcePortrait },
  isMobile,
  size = 'fullWidth'
) => {
  if (!sourceLandscape && !sourcePortrait) return null

  const responsiveType = isMobile ? sourcePortrait : sourceLandscape
  return responsiveType?.[size]?.width > responsiveType?.[size]?.height
    ? 16 / 9
    : 9 / 16
}

export const parseImage = (
  image,
  { orientation = 'landscape', raw = false, size = 'fullWidth' } = {}
) => {
  if (!image) return null
  const { sourceLandscape, sourcePortrait, title, ...rest } = image

  if (!sourceLandscape && !sourcePortrait) return undefined

  const responsiveType =
    orientation === 'landscape' ? sourceLandscape : sourcePortrait
  const fallbackType =
    orientation === 'landscape' ? sourcePortrait : sourceLandscape

  const artDirectedSrc =
    sourceLandscape?.[size] &&
    sourcePortrait?.[size] &&
    withArtDirection(sourceLandscape?.[size], [
      {
        media: '(max-width: 52em)',
        image: sourcePortrait?.[size],
      },
    ])

  const src = raw
    ? responsiveType?.file?.url || fallbackType?.file?.url
    : orientation === 'withArtDirection' && artDirectedSrc
    ? artDirectedSrc
    : responsiveType?.[size] ||
      responsiveType?.file?.url ||
      fallbackType?.[size] ||
      fallbackType?.file?.url

  return {
    ...rest,
    src,
    alt: title,
  }
}

export const isImage = ({ type, contentType }) => {
  const mediaType = contentType || type
  return handleRemoveContentfulFromContentTypeString(mediaType) === 'uiImage'
}

export const isVideo = ({ type, contentType }) => {
  const mediaType = contentType || type
  return handleRemoveContentfulFromContentTypeString(mediaType) === 'uiVideo'
}

export const parseMedia = (media, options = {}) => {
  if (!media) return undefined
  if (isVideo(media)) return parseVideo(media, options)
  if (isImage(media)) return parseImage(media, options)
  return media
}
