import React from 'react'
import { StyledContainer } from '../styles'
import NavigationList from './NavigationList'
import Copyright from './Copyright'
import FooterLogo from './FooterLogo'
import SocialMedia from './SocialMedia'

const FooterMobile = ({
  logo,
  row1NavigationItems,
  row2NavigationItems,
  row3NavigationItems,
  copyright = '',
  socialMedia,
  homeSlug,
}) => {
  return (
    <StyledContainer borderTop="1px solid black">
      <StyledContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
        mx="auto"
        maxWidth={[
          'maxWidth.xsmall',
          'maxWidth.small',
          'maxWidth.medium',
          'maxWidth.large',
          'maxWidth.xlarge',
          'maxWidth.xxlarge',
        ]}
        pt={3}
      >
        <FooterLogo logo={logo} mb={2} homeSlug={homeSlug} />
        <NavigationList navItems={row1NavigationItems} my={1} />
        <NavigationList navItems={row2NavigationItems} my={1} fontSize="16px" />
        <NavigationList
          navItems={row3NavigationItems}
          fontSize="14px"
          my={1}
          mb={2}
        />
        <SocialMedia socialMedia={socialMedia} />
      </StyledContainer>
      <StyledContainer>
        <Copyright copyright={copyright} py={2} />
      </StyledContainer>
    </StyledContainer>
  )
}

export default FooterMobile
