import React, { useState, useEffect, useCallback } from 'react'
import { Footer } from '@components/Footer'
import {
  parseQueryString,
  recursivelyGetLinkedEntriesAndSpreadFields,
} from '@utils'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@hooks/usePreviewQuery'
import { useLocale } from '@hooks'

const FooterApolloCPAContainer = props => {
  const isSpacecamp = process.env.GATSBY_SPACECAMP
  const footerID = isSpacecamp ? 'moduleNavigation' : 'moduleFooter'
  const { locale } = useLocale()
  const {
    loading: loadingNavItems,
    error: errorNavItems,
    data: dataNavItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: footerID,
    locale,
  })

  const {
    loading: loadingSiteInfo,
    error: errorSiteInfo,
    data: dataSiteInfo,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'siteInformationAndBranding',
    locale,
  })

  const [navItems, setNavItems] = useState([])
  const [siteInfo, setSiteInfo] = useState({})

  const getLinkedEntry = (arr, targetId) => {
    return arr.find(({ sys: { id } }) => id === targetId)
  }

  const getMappedSubnavLink = useCallback((subnavLink, entryIncludes) => {
    const linkedSubnav =
      subnavLink && getLinkedEntry(entryIncludes, subnavLink?.sys?.id)
    return (
      linkedSubnav && {
        id: linkedSubnav?.sys?.id,
        ...linkedSubnav?.fields,
        slug: `preview/?t=${parseQueryString('t')}&id=${linkedSubnav?.sys?.id}`,
      }
    )
  }, [])

  const getMappedSubnavItems = useCallback(
    (subnavItems, entryIncludes) => {
      const items = subnavItems
        ?.map(({ sys: { id } }) => getLinkedEntry(entryIncludes, id))
        ?.map(({ fields, sys }) => ({ ...fields, id: sys.id }))
        ?.map(({ navigationLink, ...rest }) => ({
          navigationLink: getMappedSubnavLink(navigationLink, entryIncludes),
          ...rest,
        }))
      return items
    },
    [getMappedSubnavLink]
  )

  const getMappedNavItems = useCallback(
    (data, entryIncludes) => {
      const navItemIds = data?.map(({ sys: { id } }) => id)

      const navItems = navItemIds
        ?.map(id => getLinkedEntry(entryIncludes, id))
        ?.map(({ fields, sys }) => ({ ...fields, id: sys.id }))

      const mappedNavItems = navItems?.map(
        ({
          navigationLink: {
            sys: { id },
          },
          subnavigationItems,
          ...rest
        }) => {
          const linkedEntry = getLinkedEntry(entryIncludes, id)?.fields
          const mappedSubnavigationItems = getMappedSubnavItems(
            subnavigationItems,
            entryIncludes
          )
          return {
            navigationLink: {
              id,
              slug: `preview/?t=${parseQueryString('t')}&id=${id}`,
              url: linkedEntry?.url,
              displayText: linkedEntry?.title,
              __typename: linkedEntry?.slug
                ? 'ContentfulPage'
                : 'ContentfulComponentExternalLink',
            },
            subnavigationItems: mappedSubnavigationItems,
            ...rest,
          }
        }
      )
      return mappedNavItems
    },
    [getMappedSubnavItems]
  )

  useEffect(() => {
    if (!loadingNavItems && !errorNavItems && dataNavItems) {
      if (isSpacecamp) {
        const {
          entries: {
            includes: { Entry },
            items,
          },
        } = dataNavItems
        const filteredNavData = items?.filter(
          ({ fields: { placement } }) => placement === 'Footer'
        )?.[0]?.fields
        const filteredNavItems = filteredNavData?.navigationItems
        let mappedNavItems = getMappedNavItems(filteredNavItems, Entry)
        setNavItems({ mappedNavItems })
      } else {
        const {
          entries: {
            includes: { Entry },
            items,
          },
        } = dataNavItems

        const filteredNavData = items?.[0]

        let navItems = {
          ...filteredNavData.fields,
          id: filteredNavData.sys.id,
        }

        navItems = recursivelyGetLinkedEntriesAndSpreadFields(
          navItems,
          Entry,
          []
        )

        setNavItems({ mappedNavItems: navItems })
      }
    }
  }, [
    errorNavItems,
    loadingNavItems,
    dataNavItems,
    getMappedNavItems,
    isSpacecamp,
  ])

  useEffect(() => {
    if (!loadingSiteInfo && !errorSiteInfo && dataSiteInfo) {
      const {
        entries: {
          includes: { Entry, Asset },
          items,
        },
      } = dataSiteInfo

      let filteredSiteData = { ...items?.[0]?.fields, id: items?.[0]?.sys?.id }

      filteredSiteData = recursivelyGetLinkedEntriesAndSpreadFields(
        filteredSiteData,
        Entry,
        Asset
      )

      const mappedSiteInfo = {
        title: filteredSiteData?.title,
        description: filteredSiteData?.description,
        copyright: filteredSiteData?.copyright,
        socialMedia: filteredSiteData?.socialMediaLinks,
        logo: filteredSiteData?.logo,
      }

      setSiteInfo(mappedSiteInfo)
    }
  }, [dataSiteInfo, errorSiteInfo, loadingSiteInfo])

  let logo = siteInfo?.logo
  logo = logo && {
    src: logo?.file?.url,
    alt: logo?.description || logo?.title,
  }

  return (
    <Footer
      navItems={navItems?.mappedNavItems}
      copyright={siteInfo?.copyright}
      layout={isSpacecamp ? 'spacecamp' : 'ayz'}
      socialMedia={siteInfo?.socialMedia}
      logo={logo}
      {...props}
    />
  )
}

export default FooterApolloCPAContainer
