import React from 'react'
import {
  NavigationGatsbyCDAContainer,
  NavigationApolloCPAContainer,
} from '@containers/Navigation'

const Navigation = ({ preview, ...rest }) => {
  const NavigationContainer = preview
    ? NavigationApolloCPAContainer
    : NavigationGatsbyCDAContainer

  return <NavigationContainer preview={preview} {...rest} />
}

export default Navigation
