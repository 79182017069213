import React, { useState } from 'react'

const useCopyToClipboard = link => {
  const [hasCopied, setHasCopied] = useState(false)
  let url = link || 'www.a.network'
  const copyToClipboardOnClick = () => {
    if (hasCopied) {
      return
    }

    const tempInput = document.createElement('input')
    document.body.appendChild(tempInput)
    tempInput.setAttribute('value', url)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    setHasCopied(true)

    setTimeout(() => {
      setHasCopied(false)
    }, 1000)
  }

  return [hasCopied, copyToClipboardOnClick]
}

export default useCopyToClipboard
