import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  StyledListItem,
  StyledNavContainer,
  StyledNavList,
} from '../styles'
import { useHover } from '@hooks/'
import NavigationDesktopItem from './NavigationDesktopItem'

const NavigationDesktopSubnav = ({
  subnavigationItems,
  isParentHovered = false,
  setParentHover = () => {},
  navbarOffsetTop = 72,
  navItemOffsetLeft = 0,
  linkContainerStyles,
  linkStyles,
  containerStyles = {},
}) => {
  const [hoverRefDropdown, isHovered] = useHover()

  useEffect(() => {
    setParentHover(isHovered)
  }, [isHovered, setParentHover])

  if (!subnavigationItems) return null

  const defaultLinkStyles = {
    py: 2,
    my: 2,
    fontWeight: 'normal',
  }

  const defaultlinkContainerStyles = { pr: '0px' }

  return (
    <StyledContainer
      ref={hoverRefDropdown}
      className="subnav"
      position="absolute"
      visibility={isHovered || isParentHovered ? 'visible' : 'hidden'}
      opacity={isHovered || isParentHovered ? 1 : 0}
      top={process.env.GATSBY_SPACECAMP ? `${navbarOffsetTop}px` : '70px'}
      left="0"
      width="100%"
      bg={process.env.GATSBY_SPACECAMP ? "grey50" : "yellowBright"}
      {...containerStyles}
    >
      <StyledContainer
        position="relative"
        left={`${navItemOffsetLeft}px`}
        my={3}
      >
        <StyledNavList
          display="flex"
          flexDirection="column"
          width="fit-content"
        >
          {subnavigationItems?.map(
            ({ id, navigationLink, subnavigationItems, ...rest }) => {
              return (
                <StyledListItem key={id}>
                  <NavigationDesktopItem
                    {...navigationLink}
                    {...rest}
                    linkStyles={linkStyles || defaultLinkStyles}
                    linkContainerStyles={
                      linkContainerStyles || defaultlinkContainerStyles
                    }
                    isParentHovered={isParentHovered}
                    setParentHover={setParentHover}
                  />
                  {subnavigationItems && (
                    <StyledNavContainer ml={3}>
                      {subnavigationItems?.map(
                        ({ id, navigationLink, ...rest }) => (
                          <NavigationDesktopItem
                            key={id}
                            {...navigationLink}
                            {...rest}
                            linkStyles={linkStyles || defaultLinkStyles}
                            linkContainerStyles={
                              linkContainerStyles || defaultlinkContainerStyles
                            }
                            isParentHovered={isParentHovered}
                            setParentHover={setParentHover}
                          />
                        )
                      )}
                      
                    </StyledNavContainer>
                  )}
                </StyledListItem>
              )
            }
          )}
        </StyledNavList>
      </StyledContainer>
    </StyledContainer>
  )
}

NavigationDesktopSubnav.propTypes = {
  subnavigationItems: PropTypes.arrayOf(PropTypes.object),
  isParentHovered: PropTypes.bool,
  setParentHover: PropTypes.func,
  navbarOffsetTop: PropTypes.number,
  navItemOffsetLeft: PropTypes.number,
  linkContainerStyles: PropTypes.object,
  linkStyles: PropTypes.object,
  containerStyles: PropTypes.object,
}

export default NavigationDesktopSubnav
