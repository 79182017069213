import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledLink, StyledText } from '../styles'
import { useLocale, useHover, useMobile } from '@hooks/'
import NavigationDesktopSubnav from './NavigationDesktopSubnav'
import { getAllElems, isVisible } from '../utils'

const NavigationDesktopItem = ({
  title,
  slug,
  url,
  subnavigationItems,
  anchorGroup = [],
  navbarOffsetTop,
  linkContainerStyles = {},
  linkStyles = {},
  subNavContainerStyles,
  subnavLinkContainerStyles,
  subNavLinkStyles,
  isParentHovered = false,
  setParentHover = () => {},
  forceShowNavbar = () => {},
  onFocus,
  onBlur,
  onKeyDown,
  onClick,
  CustomDesktopSubnav,
}) => {
  const { isActiveSlug } = useLocale()
  const isActive = isActiveSlug(slug)
  const [hoverRef, isHovered, setHover, ref] = useHover()
  const [isMobile] = useMobile('1020px')

  const navItemOffsetLeft = ref?.current?.getBoundingClientRect()?.left
  const anchorTagId = anchorGroup?.[0]?.anchorTagId

  const defaultHandleKeyDown = e => {
    if (!e.shiftKey && e.key === 'Tab' && isParentHovered) {
      const subnavs = getAllElems('.subnav')
      subnavs.forEach(subnav => {
        if (isVisible(subnav)) {
          const navLinks = getAllElems('.navLink', subnav)
          const lastNavLink = navLinks?.pop()
          const isLastNavLink =
            ref.current.querySelector('a') === lastNavLink.closest('a')

          if (isLastNavLink) {
            setParentHover(false)
          }
        }
      })
    }

    if (e.shiftKey && e.key === 'Tab' && !isParentHovered) {
      setHover(false)
    }

    if (e.key === 'Enter') {
      setHover(false)
      setParentHover(false)
    }
  }

  const defaultHandleFocus = () => {
    setHover(true)
    forceShowNavbar()
  }

  const defaultHandleBlur = () => {
    if (isParentHovered) {
      setHover(false)
    }
  }
  const defaultHandleClick = () => {
    setHover(false)
    if (isParentHovered) {
      setHover(false)
    }
  }

  const SubnavComponent = CustomDesktopSubnav || NavigationDesktopSubnav

  return (
    <>
      <StyledContainer
        ref={hoverRef}
        width="fit-content"
        pr={isMobile ? 4 : 5}
        onFocus={onFocus || defaultHandleFocus}
        onBlur={onBlur || defaultHandleBlur}
        onKeyDown={onKeyDown || defaultHandleKeyDown}
        onClick={onClick || defaultHandleClick}
        {...linkContainerStyles}
      >
        <StyledLink
          to={slug}
          href={url}
          anchorTagId={anchorTagId}
          aria-current={isActive ? 'page' : null}
          py={linkStyles?.paddingY || 3}
          display="inline-block"
          fontWeight={700}
          hideBottomBorder={process.env.GATSBY_SPACECAMP}
          borderBottomColor={
            isHovered || isActive
              ? linkStyles?.hoverBorderColor || 'white'
              : 'transparent'
          }
          width="fit-content"
          className="navLink"
          {...linkStyles}
          color={
            isHovered || isActive
              ? linkStyles?.hoverColor || 'inherit'
              : linkStyles?.color || 'inherit'
          }
        >
          <StyledText {...linkStyles?.text}>{title}</StyledText>
        </StyledLink>
      </StyledContainer>
      {subnavigationItems && (
        <SubnavComponent
          isParentHovered={isHovered}
          setParentHover={setHover}
          subnavigationItems={subnavigationItems}
          navbarOffsetTop={navbarOffsetTop}
          navItemOffsetLeft={navItemOffsetLeft}
          parentNavItem={{ title, slug, url, anchorGroup }}
          linkContainerStyles={subnavLinkContainerStyles || linkContainerStyles}
          linkStyles={subNavLinkStyles || linkStyles}
          containerStyles={subNavContainerStyles}
        />
      )}
    </>
  )
}

NavigationDesktopItem.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  subnavigationItems: PropTypes.arrayOf(PropTypes.object),
  anchorGroup: PropTypes.arrayOf(
    PropTypes.shape({
      anchorTagId: PropTypes.string,
    })
  ),
  navbarOffsetTop: PropTypes.number,
  linkStyles: PropTypes.object,
  linkContainerStyles: PropTypes.object,
  isParentHovered: PropTypes.bool,
  setParentHover: PropTypes.func,
  forceShowNavbar: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  CustomDesktopSubnav: PropTypes.node,
}

export default NavigationDesktopItem
