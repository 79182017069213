import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '../styles'
import FooterItem from './FooterItem'

const NavigationList = ({ navItems, fontSize = '22px', ...rest }) => {
  return (
    <StyledContainer display="flex" {...rest}>
      {navItems?.map(({ id, ...rest }, i) => (
        <StyledContainer key={id} pl={i === 0 ? 0 : 3}>
          <FooterItem fontSize={fontSize} {...rest} />
        </StyledContainer>
      ))}
    </StyledContainer>
  )
}

NavigationList.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default NavigationList
