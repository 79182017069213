import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import gsap from 'gsap'

const fadeOut = (exit, node) => {
  gsap.to(node, { opacity: 0 })
}
const fadeIn = (entry, node) => {
  gsap.from(node, { opacity: 0 })
}

const PageAnimationLink = props => {
  return (
    <TransitionLink
      exit={{
        trigger: ({ exit, node }) => fadeOut(exit, node),
        length: 1,
      }}
      entry={{
        trigger: ({ entry, node }) => fadeIn(entry, node),
        delay: 1,
        length: 1,
      }}
      {...props}
    >
      {props.children}
    </TransitionLink>
  )
}

PageAnimationLink.propTypes = {}

export default PageAnimationLink
