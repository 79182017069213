import React from 'react'
import PropTypes from 'prop-types'
import { FooterColumn, StyledText, StyledNavLink } from '../styles'
import FooterSubLink from './FooterSubLink'

const FooterItem = ({
  title = '',
  navigationLink = {},
  subnavigationItems,
  ...rest
}) => {
  return (
    <FooterColumn
      display="flex"
      flexDirection="column"
      justifyContent="start"
      width={['215px', '215px', '215px', '190px', '215px', '215px']}
      mt={2}
      alignItems={['center', 'center', 'center', 'start', 'start', 'start']}
    >
      {title && (
        <StyledText fontSize={18} fontWeight="bold" mb={3} alignItems="center">
          {navigationLink?.slug && (
            <StyledNavLink to={navigationLink?.slug} href={navigationLink?.url}>
              {title}
            </StyledNavLink>
          )}
          {!navigationLink?.slug && title.toString()}
        </StyledText>
      )}
      {subnavigationItems?.map(({ id, ...rest }) => {
        return <FooterSubLink key={id} {...rest}></FooterSubLink>
      })}
    </FooterColumn>
  )
}

FooterItem.propTypes = {
  title: PropTypes.string,
  navigationLink: PropTypes.shape({
    displayTextAlt: PropTypes.string,
    url: PropTypes.string,
    slug: PropTypes.string,
  }),
  subnavigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      navigationLink: PropTypes.shape({
        displayTextAlt: PropTypes.string,
        url: PropTypes.string,
        slug: PropTypes.string,
      }),
    })
  ),
}

export default FooterItem
