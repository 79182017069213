import gsap from 'gsap'
export * from './alignment'
export * from './dataFormatting'
export * from './maps'
export * from './media'

export const hasWindow = typeof window !== 'undefined' && window
export const isUnsupported =
  hasWindow &&
  navigator !== undefined &&
  (/MSIE 9/i.test(navigator.userAgent) ||
    /MSIE 10/i.test(navigator.userAgent) ||
    /Trident/i.test(navigator.userAgent))

export const isDebug = hasWindow && process.env.NODE_ENV === 'development'

/**
 * Clamp a number between min and max
 *
 * @param {number} value The number you want clamped
 * @param {number} min
 * @param {number} max
 *
 * @example
 *    clamp(5, 1, 10) 5
 *    clamp(50, 1, 10) 10
 *    clamp(0.5, 1, 10) 1
 */
export const clamp = (value, min, max) =>
  value < min ? min : value > max ? max : value

/**
 * Debounce a fn by a given number of ms
 *
 * @see {@link https://medium.com/@TCAS3/debounce-deep-dive-javascript-es6-e6f8d983b7a1}
 * @param {function} fn Function you want to debounce
 * @param {number} time Amount in ms to debounce. Defaults to 100ms
 * @returns {function} Your function debounced by given ms
 */

export const debounce = (fn, time = 100) => {
  let timeout

  return function() {
    const functionCall = () => fn.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

export const hexToRgb = (hex, opacity) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  return rgb && `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity || 1})`
}

export const hexToRgba = (hex, opacity) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  return (
    rgb && `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity >= 0 ? opacity : 1})`
  )
}

export const shareSocial = (platform, location, email) => {
  switch (platform) {
    case 'facebook':
      return `https://www.facebook.com/sharer.php?u=${location}`
    case 'twitter':
      return `https://twitter.com/share?url=${location}&hashtags=spacecamp,create`
    case 'email':
      return `mailto:${email}`
    case 'link':
      return `#`
    default:
      return `https://${platform}.com/`
  }
}

export const categorize = (items, categoryName) => {
  const categoryMap = {}
  if (items) {
    items.forEach(item => {
      const category = item[categoryName]
      if (!categoryMap[category]) {
        categoryMap[category] = []
      }
      categoryMap[category].push(item)
    })
  }
  const categories = Object.keys(categoryMap).filter(
    c => c !== 'undefined' && c !== 'None'
  )
  const undefinedList = categoryMap['undefined'] || []
  const noneList = categoryMap['None'] || []
  const uncategorizedList = [...undefinedList, ...noneList]
  return { categories, categoryMap, uncategorizedList }
}

export const formatDate = date => {
  var fdate = new Date(date)
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return (
    months[fdate.getMonth()] +
    ' ' +
    fdate.getDate() +
    ', ' +
    fdate.getFullYear()
  )
}

export const formatTime = date => {
  var fdate = new Date(date)
  let hours = fdate.getHours()
  let min = fdate.getMinutes()
  let ampm = hours >= 12 ? 'am' : 'pm'
  hours = hours % 12
  hours = hours || 12
  min = min < 10 ? '0' + min : min
  let formattedTime = `${hours}:${min}${ampm}`

  return formattedTime
}

export const trunc = (str, len = 100) => {
  if (typeof str !== 'string') {
    return ''
  }
  if (str.length <= len) {
    return str
  }
  return str.substr(0, len - 1) + '...'
}

export const langObj = {
  'en-US': 'English',
  'es-ES': 'Spanish',
  'es-419': 'Spanish',
  'fr-FR': 'French',
  'fr-CA': 'French',
  'de-DE': 'German',
  'it-IT': 'Italian',
  'ja-JP': 'Japanese',
  'pt-BR': 'Portuguese',
  'ko-KR': 'Korean',
  'sv-SE': 'Swedish',
}

export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const addLeadingSlash = slug =>
  slug && slug?.[0] !== '/' ? `/${slug}` : slug

export const removeTrailingSlash = slug =>
  slug && slug !== '/' ? slug.replace(/\/$/, '') : slug

export const fadeOut = (exit, node) => {
  gsap.to(node, { opacity: 0 })
}
export const fadeIn = (entry, node) => {
  gsap.from(node, { opacity: 0 })
}
