import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledListItem,
  StyledMobileOverlayContainer,
  StyledNavList,
} from '../styles'
import NavigationMobileItem from './NavigationMobileItem'
import BackBtn from '../components/BackBtn'
const NavigationMobileOverlay = ({
  parentNavItem,
  navItems,
  navbarOffsetTop = 72,
  slideDirection = 'left',
  toggled = false,
  setToggled = () => {},
  linkStyles = {},
  linkContainerStyles = {},
  listStyles = {},
  overlayStyles = {},
}) => {
  const [activeSubnav, setActiveSubnav] = useState(null)

  return (
    <StyledMobileOverlayContainer
      position="fixed"
      width="100vw"
      height={`calc(100vh - ${navbarOffsetTop}px)`}
      top={!parentNavItem ? `${navbarOffsetTop}px` : 0}
      left="0"
      zIndex={2000}
      toggled={toggled}
      slideDirection={slideDirection}
      bg={process.env.GATSBY_SPACECAMP ? "grey50" : "yellowBright"}
      color={process.env.GATSBY_SPACECAMP ? "white" : "black"}
      opacity={0.97}
      {...overlayStyles}
    >
      {parentNavItem && (
        <BackBtn
          toggled={toggled}
          setToggled={setToggled}
          activeSubnav={activeSubnav}
          parentNavItem={parentNavItem}
        />
      )}
      <StyledNavList
        px={3}
        py={3}
        m="0 auto"
        width="100vw"
        maxWidth={[
          'maxWidth.xsmall',
          'maxWidth.small',
          'maxWidth.medium',
          'maxWidth.large',
          'maxWidth.xlarge',
          'maxWidth.xxlarge',
        ]}
        {...listStyles}
      >
        {parentNavItem && (
          <StyledListItem {...linkContainerStyles}>
            <NavigationMobileItem
              {...parentNavItem}
              toggled={toggled}
              activeSubnav={activeSubnav}
              linkStyles={linkStyles}
            />
          </StyledListItem>
        )}
        {navItems?.map(({ id, navigationLink, ...rest }, i, arr) => (
          <StyledListItem
            key={id}
            pl={parentNavItem ? 3 : 0}
            {...linkContainerStyles}
          >
            <NavigationMobileItem
              {...navigationLink}
              {...rest}
              toggled={toggled}
              setToggled={setToggled}
              activeSubnav={activeSubnav}
              setActiveSubnav={setActiveSubnav}
              isLastItem={i === arr.length - 1}
              navbarOffsetTop={navbarOffsetTop}
              parentNavItem={parentNavItem}
              linkStyles={process.env.GATSBY_SPACECAMP ? linkStyles : {...linkStyles, hoverBorderColor: "heroNavColor"}}
              overlayStyles={overlayStyles}
            />
          </StyledListItem>
        ))}
      </StyledNavList>
    </StyledMobileOverlayContainer>
  )
}

NavigationMobileOverlay.propTypes = {
  parentNavItem: PropTypes.object,
  navItems: PropTypes.arrayOf(PropTypes.object),
  navbarOffsetTop: PropTypes.number,
  slideDirection: PropTypes.oneOfType(['up', 'down', 'left', 'right']),
  toggled: PropTypes.bool,
  setToggled: PropTypes.func,
}

export default NavigationMobileOverlay
