import { useState, useCallback, useRef } from 'react'
import useDebounce from './useDebounce'

const useMeasure = ({ initHeight = 0, initWidth = 0, delay = 250 } = {}) => {
  const [height, setHeight] = useState(initHeight)
  const [width, setWidth] = useState(initWidth)
  const ref = useRef()

  const updateDimensions = () => {
    if (ref.current) {
      setHeight(ref.current.getBoundingClientRect().height)
      setWidth(ref.current.getBoundingClientRect().width)
    }
  }

  const handleResize = useDebounce(updateDimensions, delay)

  const callbackRef = useCallback(
    node => {
      if (ref.current) {
        window.removeEventListener('resize', handleResize)
      }

      ref.current = node

      if (ref.current) {
        handleResize()
        window.addEventListener('resize', handleResize)
      }
    },
    [handleResize]
  )

  return { ref: callbackRef, height, width }
}

export default useMeasure
