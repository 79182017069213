import React, { useState, useEffect } from 'react'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
  GET_NAVIGATION,
} from '@hooks/usePreviewQuery'
import { useLocale } from '@hooks'
import { recursivelyGetLinkedEntriesAndSpreadFields } from '@utils/'
import { NavigationLayout } from '@components/Navigation'

const NavigationApolloCPAContainer = props => {
  const { locale } = useLocale()

  const {
    loading: loadingNavItems,
    error: errorNavItems,
    data: dataNavItems,
  } = usePreviewQuery({
    query: GET_NAVIGATION,
    id: 'Header',
    locale,
  })

  const {
    loading: loadingSiteInfo,
    error: errorSiteInfo,
    data: dataSiteInfo,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'siteInformationAndBranding',
    locale,
  })

  const [navItems, setNavItems] = useState([])
  const [siteInfo, setSiteInfo] = useState({})

  useEffect(() => {
    if (!loadingNavItems && !errorNavItems && dataNavItems) {
      const {
        entries: { includes, items },
      } = dataNavItems

      const Entry = includes?.Entry || []
      const Asset = includes?.Asset || []
      const { sys, fields } = items?.[0]
      let mappedNavItems = {
        ...fields,
        id: sys?.id,
        type: sys?.contentType?.sys?.id,
      }

      mappedNavItems = recursivelyGetLinkedEntriesAndSpreadFields(
        mappedNavItems,
        Entry,
        Asset
      )

      const formatNavLink = ({ title, ...navlinkRest }) => ({
        displayText: title,
        ...navlinkRest,
      })

      mappedNavItems = mappedNavItems?.navigationItems?.map(
        ({ navigationLink, subnavigationItems, ...rest }) => {
          const newNavLink = formatNavLink(navigationLink)
          const newSubNavItems = subnavigationItems?.map(
            ({ navigationLink: subNavigationLink, ...subNavRest }) => ({
              navigationLink: formatNavLink(subNavigationLink),
              ...subNavRest,
            })
          )
          return {
            ...rest,
            navigationLink: newNavLink,
            subnavigationItems: newSubNavItems,
          }
        }
      )

      setNavItems(mappedNavItems)
    }
  }, [errorNavItems, loadingNavItems, dataNavItems])

  useEffect(() => {
    if (!loadingSiteInfo && !errorSiteInfo && dataSiteInfo) {
      const {
        entries: { includes, items },
      } = dataSiteInfo

      const Entry = includes?.Entry || []
      const Asset = includes?.Asset || []
      const { sys, fields } = items?.[0]
      let mappedSiteInfo = {
        ...fields,
        id: sys?.id,
        type: sys?.contentType?.sys?.id,
      }

      mappedSiteInfo = recursivelyGetLinkedEntriesAndSpreadFields(
        mappedSiteInfo,
        Entry,
        Asset
      )

      mappedSiteInfo.logo = {
        src: mappedSiteInfo?.logo?.file?.url,
        alt: mappedSiteInfo?.logo?.description || mappedSiteInfo.logo?.title,
      }

      mappedSiteInfo.secondaryLogo = {
        src: mappedSiteInfo?.secondaryLogo?.file?.url,
        alt:
          mappedSiteInfo?.secondaryLogo?.description ||
          mappedSiteInfo.secondaryLogo?.title,
      }

      setSiteInfo(mappedSiteInfo)
    }
  }, [dataSiteInfo, errorSiteInfo, loadingSiteInfo])

  return (
    <NavigationLayout
      navItems={navItems}
      logo={siteInfo?.logo}
      secondaryLogo={siteInfo?.secondaryLogo}
      {...props}
    />
  )
}

export default NavigationApolloCPAContainer
